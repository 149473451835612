.Layout__setting__main {
  width: 100vw;
  height: calc(100vh);
  display: flex;
  overflow: auto;
  z-index: 10000 !important;
}

.Layout__setting__left {
  width: 329px;
  height: 100%;
  background: #1b2734;
  transition: 1s;
  z-index: 2;
  z-index: 100 !important;
}

.Layout__setting__rigth {
  width: calc(100vw - 329px);
  min-width: 300px;
  background: #ffffff;
  overflow: auto;
  position: relative;
  z-index: 100 !important;
}

.Layout__setting__user__data {
  margin-top: 34px;
  margin-bottom: 86px;
  margin-left: 42px;
  margin-right: 10px;
  display: flex;
}

.Layout__setting__user__data .user__data__text {
  margin-left: 21px;
}

.user__data__text p {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.user__data__text span {
  font-size: 18px;
  line-height: 30px;
}

/* buttons */
.Layout__setting__links {
  background: transparent;
  width: 316px;
  min-height: 50px;
  border-radius: 0px 29px 29px 0px;
  /* text */
  font-size: 20px;
  line-height: 30px;

  color: #ffffff;
  text-align: start;
  display: flex;
  align-items: center;
}

.Layout__setting__links img {
  margin-right: 27px;
  margin-left: 30px;
}

.Layout__setting__links__active,
.Layout__setting__links:hover {
  background: #7894b434;
}

.Layout__setting__close {
  background: transparent;
  position: absolute;
  right: 12px;
  top: 12px;
}

/* para los titles de las screan */
.Layout__setting__screens__title {
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  color: #7894b4;
  margin-top: 70px;
}

.settings__information__avatar {
  text-align: center;
  margin-bottom: 20px;
}

/* para los inputs de el engrane */
.settings__fields__inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.settings__fields__main {
  width: 70%;
  height: 51px;
  background: #eaeff4;
  border-radius: 7px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}

.settings__fields__main input {
  border: none;
  font-size: 20px;
  line-height: 30px;

  color: #1b2734;
  width: 100%;
  background: transparent;
}

.settings__fields__main button {
  border: none;
  background: transparent;
}

.settings__fields__labels {
  font-weight: 500;
  font-size: 15px;
  color: #7894b4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* button para todas las screens */
.settings__button__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.settings__button__main {
  width: 278px;
  height: 66px;
  background: #267dff;
  border-radius: 7px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-top: 25px;
}

.settings__button__calcel {
  background-color: transparent;
  font-size: 18px;
  line-height: 21px;
  color: #7894b4;
  margin-top: 12px;
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 1000.98px) and (orientation: portrait) {
  .settings__fields__inline {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .settings__fields__main {
    width: 100%;
  }
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .Layout__setting__main {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }

  .Layout__setting__left {
    background: #1b2734;
    width: 100vw;
    height: auto;
  }

  .Layout__setting__rigth {
    background: #ffffff;
    position: static;
    width: 100vw;
    overflow: visible;
  }

  .settings__fields__inline {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .settings__fields__main {
    width: 100%;
  }

  /* buttons */
  .Layout__setting__links__container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5px;
  }
  .Layout__setting__links {
    width: auto;
    border-radius: 29px;
  }

  .Layout__setting__links img {
    margin-right: 35px;
    margin-left: 35px;
  }

  .Layout__setting__links span {
    display: none;
  }

  .Layout__setting__user__data {
    margin-bottom: 15px;
    align-items: center;
  }

  .user__data__text p {
    line-height: 17px;
    font-size: 15px;
  }
  .user__data__text span {
    line-height: 15px;
    font-size: 13px;
  }
}
/*landscape*/
@media (min-width: 600px) and (max-width: 1100.98px) and (orientation: landscape) {
  .Layout__setting__main {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }

  .Layout__setting__left {
    background: #1b2734;
    width: 100vw;
    height: auto;
  }

  .Layout__setting__rigth {
    background: #ffffff;
    position: static;
    width: 100vw;
    overflow: visible;
  }

  .settings__fields__inline {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .settings__fields__main {
    width: 100%;
  }

  /* buttons */
  .Layout__setting__links__container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5px;
  }
  .Layout__setting__links {
    width: auto;
    border-radius: 29px;
  }

  .Layout__setting__links img {
    margin-right: 35px;
    margin-left: 35px;
  }

  .Layout__setting__links span {
    display: none;
  }

  .Layout__setting__user__data {
    margin-bottom: 10px;
  }

  .user__data__text p {
    line-height: 22px;
  }
}
/*fin movil*/
