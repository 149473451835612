.user__output__line__main {
  margin-bottom: 100px;
}

.user__output__line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #141d27;
  margin-bottom: 15px;
  padding: 3px;
  border-radius: 7px;
}

.user__output__line__text {
  width: 100%;
  padding-left: 5px;
  text-align: start;
}

.user__output__line__original_sentence {
  margin: 0 5px 5px 0;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .user__output__line__main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .user__output__line__main {
    padding-bottom: 50px;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .user__output__line__main {
    padding-left: 20px;
    padding-right: 20px;
  }
  .user__output__line__main {
    padding-bottom: 50px;
  }
}
/*fin movil*/
