.contenedor-conversacion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  max-height: 60px;
}

.conversation-titles p {
  margin: 0;
}

.conversation-titles .conversation-resolved {
  font-size: 15px;
  color: rgb(30, 182, 60);
}

.conversation-titles .conversation-no-resolved {
  font-size: 15px;
  color: rgb(248, 246, 246);
}

#title-conversation,
#first-message {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#title-conversation {
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#first-message {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #6c859f;
}

/* para conversaciones personales */
.conversation-item-personal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}

.conversation-item-personal-avatar {
  display: flex;
  align-items: center;
  max-width: 70%;
  width: 70%;
}

.conversation-item-personal-avatar img {
  margin-right: 10px;
}

.status-resolved-hilo {
  color: rgb(18, 165, 55);
  margin: 0;
  height: 30px;
}

.status-time-hilo {
  font-weight: 300;
  font-size: 10px;
  color: #6c859f;
  margin-right: 3px;
}

.status-usuario-agregado {
  font-weight: 300;
  font-size: 15px;
  color: #7fe55b;
  min-width: 100px;
}
