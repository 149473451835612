.created-by-or-verified_by{
  position: relative;

  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
}