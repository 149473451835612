.suscription__error__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.suscription__error__titles__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
}

.suscription__error__text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
}

.suscription__error__text img {
  margin-top: 6px;
  margin-right: 6px;
  width: 25px;
}

.suscription__error__text span {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ff5562;
}

.suscription__error_text__secondary {
  font-size: 22px;
  line-height: 30px;
  color: #1b2734;
}

.suscription__error__button {
  width: 100%;
  height: 66px;
  background: #267dff;
  border-radius: 7px;
  font-weight: 500;
  font-size: 25px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
}
