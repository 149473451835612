.context-menu-menu-share-track-item {
	height: 40px;
	padding-top: 6%;
	padding-bottom: 6%;
	font-weight: 500;
	font-size: 15px;
	color: #ffffff;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	cursor: pointer;
	margin-right: 10px;
	margin-left: 10px;

	display: flex;
	justify-content: space-between;
}

.context-menu-menu-share-track-item:hover {
	background: rgba(100, 148, 237, 0.123);
}

.border-divider-item-shared-track {
	border-bottom: 1px solid rgb(57, 80, 105);
	margin-right: 10px;
	margin-left: 10px;
}

.menu-menu-share-track-item-text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 85%;
	height: 20px;
}

.menu-menu-share-track-item-icon {
	width: 15%;
}
