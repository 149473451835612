.settings__information__main {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
}

.settings__phone__container {
  border: none;
  width: 70%;
  z-index: 1;
}

.settings__phone__input_class {
  width: 100% !important;
  height: 45px !important;
  background: #eaeff4 !important;
  border-radius: 7px;
  border: none !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1b2734 !important;
}

.settings__phone__input_class:focus {
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.settings__phone__input_class::placeholder {
  color: #1b2734 !important;
}

.settings__phone__button {
  border: 0 !important;
  background: transparent !important;
  border-right: 0.5px solid #779bc7 !important;
}

.settings__phone__dropdown {
  border: none !important;
}

.settings__phone__search {
  border: none !important;
}

.edit__password__back {
  background-color: transparent;
  position: absolute;
  top: 29px;
  left: 29px;
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 1000.98px) and (orientation: portrait) {
  .settings__fields__inline {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .settings__phone__container {
    width: 100%;
  }
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .settings__phone__container {
    width: 100%;
  }
}
/*landscape*/
@media (min-width: 600px) and (max-width: 1100.98px) and (orientation: landscape) {
  .settings__phone__container {
    width: 100%;
  }
}
/*fin movil*/
