.container-invitation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.email-invitation {
  width: 80%;
  height: 45px;
  color: white;
  background-color: gray;
  border-radius: 7px;
  padding: 10px;
  border: 0;
}

.title-invitation {
  margin-top: 20%;
  margin-bottom: 5%;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  text-align: center;
  color: #ffffff;
}

.send-invitation:hover {
  border: none;
  background-color: #524949;
  color: none;
}

.send-invitation {
  width: 180px;
  height: 50px;
  background: none;
  color: white;
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 35px;
  margin-bottom: 5%;
}
