.mention {
	color: #21a3f8;
	text-decoration: none;
	position: relative;
}

.mentionSuggestions {
	position: absolute;
	bottom: 5px;
	left: 5px;

	background: #1b2734;
	border-radius: 10px;
	cursor: pointer;
	padding: 1rem 0.5rem;
	display: inline-flex;
	flex-direction: column;
	box-sizing: border-box;
	max-height: 75%;
	overflow-y: scroll;

	z-index: 100;
	/* Debe de tene rel mismo ancho que */
}

.mentionSuggestionsEntryContainer {
	width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
	display: table-cell;
	vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
	padding-left: 8px;
}

.mentionSuggestionsEntry {
	padding: 7px 10px 3px 10px;
	border-radius: 7px;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.mentionSuggestionsEntry:active {
	background: #81b5ee;
}

.mentionSuggestionsEntryFocused {
	composes: mentionSuggestionsEntry;
	background-color: #81b5ee;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	font-weight: normal;
	font-size: 22px;
	line-height: 29px;

	color: #ffffff;
}

.mentionSuggestionsEntryText {
}

.mentionSuggestionsEntryTitle {
	font-size: 20%;
	color: white;
}

.mentionSuggestionsEntryAvatar {
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}
