/* -- Modal Styles -- */
.plus__button__full__screen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #1b2734;
  /* Safari 4+ */
  -webkit-animation: animacion_abrir_modal 1s;
  /* Fx 5+ */
  -moz-animation: animacion_abrir_modal 1s;
  /* Opera 12+ */
  -o-animation: animacion_abrir_modal 1s;
  /* IE 10+, Fx 29+ */
  animation: animacion_abrir_modal 1s;
  z-index: 1000 !important;
  padding: 0 23px 23px 23px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.plus__button__full__screen::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}

.close__plus__button__animation {
  animation: animacion_cerrar_modal 1s;
}

.btn__plus__button {
  width: 81.65px;
  height: 81.65px;
  border-radius: 50px;
  background: transparent;
  border: 0;
  transition: 0.5s all ease;
}

.btn__plus__button__absolute {
  left: 69px;
  z-index: 1;
  background-color: transparent;

  position: fixed;
  bottom: 95px;
  cursor: pointer;
  transition: all 1s;
}

.btn__plus__button__absolute img {
  width: 75px;
}

.btn__plus__button img {
  width: 120px;
  height: 1px;
}

.btn__plus__button:focus {
  outline: none;
}

.btn__plus__button:hover {
  transform: scale(1.1);
}

.btn__plus__button img {
  width: 81.65px;
  height: 81.65px;
  transition: 0.5s all;
}

/*movil*/
/*Portrait*/
/*Default*/
/* @media (max-width: 575.98px) and (orientation: portrait) {} */
/*landscape*/
/* @media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {} */
/*fin movil*/

@keyframes animacion_abrir_modal {
  from {
    left: -100vw;
    right: 100vw;
    overflow: hidden;
  }
  to {
    left: 0;
    right: 0;
    width: 100%;
  }
}

@keyframes animacion_cerrar_modal {
  0% {
    left: 0;
    right: 0;
    width: 100%;
  }

  100% {
    left: -100vw;
    right: 100vw;
  }
}
