.flashcard-preview-container {
	display: flex;
	justify-content: space-around;
	position: relative;
	top: 10px;
}

.flashcard-side {
	height: 50vh;
	width: 241.75px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-origin: content-box;
	padding: 10px;
}

.front {
	background-color: #000000;
}

.back {
	background: #f2f2f2;
}

.front-txt {
	margin: 28px;
	font-size: 26px;
	line-height: 30px;
	color: white;
}

.front-txt span:hover {
	cursor: pointer;
	font-weight: bold;
	font-size: 24px;
}

.answer-txt {
	margin: 28px;
	font-size: 22px;
	line-height: 26px;
	color: #4f4f4f;
}

.original-sentence {
	font-size: 12px;
	color: #4f4f4f;
	font-style: italic;
}

.flashcard-step-btn-container {
	display: flex;
	justify-content: center;
	margin-top: 25px;
	margin-bottom: 25px;
}

.flashcard-salir-btn-container {
	display: flex;
	justify-content: center;
	margin-top: 25px;
	margin-bottom: 25px;
}

.flashcard-step-btn,
.flashcard-salir-btn {
	width: 208px;
	height: 37px;
	border-radius: 7px;
	color: #ffffff;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	background-image: linear-gradient(182.95deg, #ff5962 16.06%, #fd935e 91.7%);
	padding: 1px;
	border: none;
}

.flashcard-step-btn:hover,
.flashcard-salir-btn:hover {
	cursor: pointer;
}

.flashcard-step-btn span,
.flashcard-salir-btn span {
	background: #1b2734;
	border-radius: 7px;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.flashcard-step-btn span:hover,
.flashcard-salir-btn span:hover {
	background: linear-gradient(182.95deg, #ff5962 16.06%, #fd935e 91.7%);
}

.flashcard-step-btn:focus,
.flashcard-salir-btn:focus {
	outline: 0;
}

.pista-btn-flashcard {
	position: absolute;
	bottom: 5.2px;
	right: 11.19px;
}

.pista-btn-flashcard:hover {
	background: linear-gradient(192.11deg, #ff5962 16.06%, #fd935e 91.7%);
}

.instrucciones-de-cloze {
	position: absolute;
	color: #ffffff;
	font-size: 15px;
	font-weight: 600;
	line-height: 18px;
	top: -31px;
}

.pista-flashcard {
	position: absolute;
	color: white;
	bottom: 12px;
	padding-right: 20%;
	width: 90%;
	text-align: start;
}

.mensaje-de-exito-agregar-fc {
	font-size: 30px;
	line-height: 35px;
}

/* nose si estos estilos se estan usando. alguien que revise si son necesario */
#titulo-flashcard-output {
	text-align: center;
	margin-bottom: 66px;
}

#titulo-flashcard-output h3 {
	font-weight: bold;
	font-size: 34px;
	line-height: 40px;
	/* identical to box height */
	text-align: center;
	color: #ffffff;
}

#contenedor-flashcard {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

*:focus {
	outline: none;
}

#div-flashcard-back {
	border-radius: 10px;
	background: white;
	width: 241.75px;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	align-items: center;
	justify-content: center;
	height: 340.79px;
	text-align: center;
}

#div-flashcard-back textarea {
	width: 100%;
	border: none;
	border-radius: 10px;
	margin: 0px;
	padding: 10px;
	max-height: 340.79px;
	overflow-y: scroll;
	display: block;

	font-weight: 500;
	font-size: 19px;
	line-height: 22px;
	color: #4f4f4f;
	text-align: center;
}

#div-flashcard-back textarea:focus {
	border: none;
	outline: none;
	outline-width: 0;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

#div-flashcard-back p::placeholder {
	/* Firefox, Chrome, Opera */
	color: #c4c4c4;
	font-weight: bold;
	font-size: 26px;
	font-size: 19px;
	line-height: 30px;
	padding: 5px;
	text-align: center;
}

.contenedor-btn-enviar {
	text-align: center;
	margin-top: 67.21px;
	/* margin-botton: 44px; */
}

.contenedor-btn-enviar button {
	width: 208px;
	height: 37px;

	border-radius: 7px;
	border-color: #ff5962;
	color: #ffffff;
}

.contenedor-btn-enviar button:hover {
	background: linear-gradient(192.11deg, #ff5962 16.06%, #fd935e 91.7%);
	color: #ffffff;
}

.btn-pista {
	padding: 0;
	margin: 0;
	width: 32.39px;
	height: 32.39px;

	border-radius: 50%;
	color: black;
	border: solid 2px transparent;
	background-image: linear-gradient(-45deg, #ff5962 16.06%, #fd935e 91.7%),
		linear-gradient(-45deg, #ff5962 16.06%, #fd935e 91.7%),
		linear-gradient(-45deg, #ff5962 16.06%, #fd935e 91.7%);
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px black inset;
}

button.btn-pista:hover {
	box-shadow: none;
}

.simbolo-de-pista {
	position: relative;
	bottom: 2px;
	right: 0.2px;
}
/*
    Internet Explorer 10-11
    :-ms-input-placeholder {
      color: red;
    }
  
    Microsoft Edge
    ::-ms-input-placeholder {
      color: orange;
    }
  */
