.recuento-de-outputs-corregidos {
  position: absolute;
  bottom: 19px;
  right: 19px;
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
}

.iconos-superiores {
  margin: 0 26px 24px 26px !important;
  /*display: -webkit-flex;
  display: -ms-flexbox;*/
  display: flex;
  justify-content: space-between;
}
.iconos-superiores button span {
  color: white;
}
.iconos-superiores button span img {
  margin: 5px;
}

#btn-modal-close {
  padding: 0px;
  position: absolute;
  right: 10px;
  top: 10px;
}
/*
#contenedor-principal {
  width: 876px;
  height: 664px;
  display: flex;
  flex-direction: column;
  position: relative;
}
*/
#btn-historial-de-respuestas img {
  margin-right: 5.03px;
}

#historial-de-respuestas:hover {
  cursor: pointer;
}

#btn-historial-de-respuestas {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding: 0;

  /* Gradiente Orange */
  background: linear-gradient(184.09deg, #ff5962 16.06%, #fd935e 91.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#c-titulo-instrucciones {
  position: relative;
  text-align: center;
  margin: 0 auto 50px auto;
}

#pregunta {
  position: relative;

  height: auto;

  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  color: #ffffff;
}

#indicaciones {
  margin-bottom: 0;
  display: inline-block;
  width: 305px;
  height: 35px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

#span-historial-de-respuestas {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin-right: 26px;
  float: right;
}

#teacher-response {
  /* Good job! */
  width: auto;
  height: auto;

  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

#c-crear-flashcard-output {
  display: flex;
  justify-content: flex-end;
}

#envoltura-btn-add-flashcard-output {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  padding: 0;
  width: 40.75px;
  height: 40.75px;
  background: #1b2734;
  border-radius: 50%;

  box-shadow: -2px -3px 3px #31455c, 2px 3px 2px #0b1015;
}

.btn-add-flashcard-output {
  padding: 0;
  margin: 0;
  width: 32.39px;
  height: 32.39px;

  border-radius: 50%;

  border: solid 2px transparent;
  background-image: linear-gradient(225deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(225deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(225deg, #ff5962 16.06%, #fd935e 91.7%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #1b2734 inset;
}

button.btn-add-flashcard-output:hover {
  box-shadow: none;
}

.gradient-orange {
  position: absolute;
  width: 12px;
  height: 12px;
  line-height: 28px;
  /* Gradiente Orange */
  border-image: -webkit-linear-gradient(192.11deg, #fd935e 16.06%, #ff5962 91.7%);
}

/*
.scroll-tabla-de-respuestas{
    max-height: calc(58vh - 2rem);
    overflow-y: auto;
}
*/
.gradient-wrapper {
  display: table;
  padding: 2px;
  border-radius: 7px;
  display: inline-block;
  background: #ff5962 16.06%;
  background: -webkit-linear-gradient(192.11deg, #ff5962 16.06%, #fd935e 91.7%);
  background: -o-linear-gradient(192.11deg, #ff5962 16.06%, #fd935e 91.7%);
  background: -moz-linear-gradient(192.11deg, #ff5962 16.06%, #fd935e 91.7%);
  background: linear-gradient(192.11deg, #ff5962 16.06%, #fd935e 91.7%);
}

#maincircle {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  vertical-align: middle;
  width: 199px;
  height: 34px;
  border-radius: 7px;
  background: #1b2734;
}

.gradient-wrapper:hover {
  cursor: pointer;
}

#c-buttons-back-next {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  bottom: 36px;
}

#c-buttons-back-next button {
  width: 199px;
  height: 34px;
  border-radius: 7px;
}

.btn-next-output {
  padding: 0;
  margin: 0;
  width: 199px;
  height: 34px;
  border-radius: 7px;

  border: solid 1px transparent;
  background-image: linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #1b2734 inset;

  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
}

button.btn-next-output:hover {
  box-shadow: none;
  color: white;
}

#icon-comment {
  display: flex;
  align-items: center;
  position: absolute;
  height: 21px;
  width: 101px;
  padding-left: 5px;
  padding-right: 5px;

  top: -20px;
  right: -100px;
  background: transparent;
  border-radius: 7px;
}

#icon-comment:hover {
  /*width: 101px;

    background: linear-gradient(190.6deg, #1EB8F1 12.28%, #015BBA 91.67%);
    border-radius: 7px;*/
}

#btn-show-comment {
  margin: auto;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.text-to-speech {
  cursor: pointer;
}
