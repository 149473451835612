#first-text{
  font-weight: bold;
  font-size: large;
  text-align: center;
  color: #FFFFFF;
}

#second-text{
  font-weight: normal;
  font-size: large;
  text-align: center;
  color: #FFFFFF;
}

.btn-solicitud-de-frases-enviada{
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #7FE55B;
  border-radius: 7px;

  width: 228px;
  height: 43px;

  font-weight: bold;
  font-size: medium;
  color: #FFFFFF;

}