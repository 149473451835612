.progress-all-general-container {
  width: 135px;
  min-width: 135px;
  display: flex;
  justify-content: center;
}

#container-progress {
  position: relative;
  position: absolute;
  bottom: 175px;
  left: 0px;
  width: auto;
  height: 129px;
  border-radius: 0px 7px 70px 0px;
  z-index: 1;
}

#container-progress:hover {
  box-shadow: 1px 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: #1b2734;
}

.description-IOR {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}
