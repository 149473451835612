.contenedor-track-item {
  height: 420px;
  width: 224px;
}

.track-item-photo-user-container {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -20px;
  display: flex;
  justify-content: center;
}

.track-item-photo-user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: 100 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.track-item-information {
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 100%;
}

.track-item-new-margin-normal {
  width: 100%;
  margin-top: 10px;
}

.track-item-new-margin-top {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.track-item-new {
  min-width: 10px;
  max-width: 10px;
  max-height: 10px;
  min-height: 10px;
  background: #ff5562;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 4px;
}

.contenedor-track-item-fondo {
  position: relative;
  width: 224px;
  height: 224px;
  border-radius: 7px;
  background: #18222c;

  background-size: 100 100%;
  background-position: center;
  background-size: cover;
}

.contenedor-track-item-fondo-mylikes {
  width: 224px;
  height: 224px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}

.contenedor-track-item-fondo-mylikes img {
  width: 55%;
}

.contenedor-track-item-fondo-card {
  background-color: red;
  width: 100%;
  height: 100%;
}

.contenedor-track-item-fondo:hover {
  box-shadow: inset 1000px 0 0 0 rgba(8, 8, 8, 0.671);
}

.contendor-track-items {
  display: none;
}

.contenedor-track-item-fondo:hover .contendor-track-items {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.track-item-like {
  width: 50px;
  text-align: center;
  padding-left: 10px;
}

.track-item-menu {
  width: 50px;
}

.track-item-play {
  width: 100px;
  text-align: center;
}

#img-play {
  margin-left: -8px;
  width: 80px;
}

.track-item-titulo {
  margin-bottom: -5px;
  font-size: 15px;
  line-height: 18px;
  color: #f2f2f2;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.track-item-titulo:hover {
  text-decoration: underline;
}

.track-item-artista-link {
  font-size: 10px;
  font-weight: 900;
  color: #f2f2f2;
  line-height: 1.1 !important;
}

.track__item__artists__names {
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  margin-top: 3.5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.track-item-artista-link:hover {
  color: #f2f2f2;
}

.track-item-photo-user:hover .track-item-tooltip {
  display: block;
}
.track-item-photo-user:hover .track-item-note-icon {
  display: block;
}

.track-item-photo-user:hover .track-item-tooltip-name-user {
  display: block;
}

/* tooltip de nota de usuario */
.track-item-note-icon {
  width: 17px;
  height: 17px;
  display: none;
}

.track-item-tooltip {
  position: absolute;
  width: 90%;
  max-height: 110px;
  min-height: 110px;
  top: -115px;
  background: black;
  border-radius: 4px;
  padding: 8px;
  display: none;
}

.track-item-tooltip:before {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 45%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid black;
}

.track-item-tooltip-name {
  font-size: 13px;
  line-height: 15px;
  color: #81b5ee;
  text-align: start;
}

.track-item-tooltip-note {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 65px;
}

/* tooltip para nombre sin nota */

.track-item-tooltip-name-user {
  position: absolute;
  min-width: 100px;
  padding: 2.5px;
  top: -26px;
  background: #000000;
  border-radius: 5px;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  display: none;
}

.btn-controles-track {
  background: transparent;
  border: none;
  width: 75px;
}

.btn-controles-track:focus {
  background: transparent;
  outline: none;
}

.play-icon-controles {
  min-width: 90px;
  max-width: 90px;
  margin-left: -13px;
}

.stop-icon-controles {
  min-width: 80px;
  max-width: 80px;
  margin-left: -4px;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .track-item-tooltip-name-user {
    font-size: 13px;
    line-height: 15px;
  }

  .track-item-photo-user-container {
    bottom: -15px;
  }

  .track-item-photo-user {
    width: 35px;
    height: 35px;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .track-item-tooltip-name-user {
    font-size: 13px;
    line-height: 15px;
  }

  .track-item-photo-user-container {
    bottom: -15px;
  }

  .track-item-photo-user {
    width: 35px;
    height: 35px;
  }
}
/*fin movil*/
