.blue-outline-button{
  border: 2px solid #2F80ED;
  border-radius: 7px;
  min-width: 150px;

  font-weight: normal;
  font-size: 20px;
  color: #FFFFFF;
  background: transparent;

}

.blue-outline-button:hover{
  color: #FFFFFF;
  background: #2F80ED;
}

.blue-button{
  border: 2px solid #2F80ED;
  border-radius: 7px;
  min-width: 150px;

  font-weight: normal;
  font-size: 20px;
  color: #FFFFFF;
  background: #2F80ED;
}