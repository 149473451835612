.drawer__coco__main {
  position: fixed;
  width: 410px;
  height: calc(100% - 76px);
  top: 2px;
  background: #1b2734;
  border-radius: 20px 0px 0px 20px;
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px 0px 0px 20px;
  right: -100vw;
  transition: 0.5s all;
  z-index: 10000;
}

.drawer__coco__open {
  -webkit-animation: open_my_tools 0.5s;
  /* Fx 5+ */
  -moz-animation: open_my_tools 0.5s;
  /* Opera 12+ */
  -o-animation: open_my_tools 0.5s;
  /* IE 10+, Fx 29+ */
  animation: open_my_tools 0.3s;
  right: 0;
}

.drawer__coco__close {
  right: -410px;
}

.drawer__coco__gradient {
  background: radial-gradient(
    49.9% 49.9% at 49.87% 50.1%,
    #183d65 0%,
    rgba(37, 54, 72, 0) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
}

.drawer__coco__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: relative;
}

.drawer__coco__user {
  text-align: center;
  margin-top: 50px;
  z-index: 1;
}

.drawer__coco__links {
  text-align: center;
}

.drawer__coco__my__tools {
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #445d78;
}

.link-mis-cosas {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  background: transparent;
}

.link-mis-cosas:hover {
  color: white;
}

#cerrar-sesion {
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  cursor: pointer;

  color: #ffffff;
}

.drawer__coco__user__border {
  width: 135px;
  height: 135px;
  padding: 3px;
  border-radius: 50%;

  border: 4px solid transparent;
  background: linear-gradient(#ff5562 14.22%, #fd9d5d 90.1%);
  background-clip: padding-box;
  position: relative;

  display: flex;
  justify-content: center;
}

.drawer__coco__icon__camera {
  position: absolute;
  border-radius: 10px;
  bottom: 12px;
  padding: 5px;
  cursor: pointer;
}

.drawer__coco__user__my__tools {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
}

.drawer__coco__user__name {
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 7px;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .drawer__coco__main {
    width: 100vw;
    height: 100vh;
  }

  .drawer__coco__close {
    right: -100vw;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .drawer__coco__main {
    width: 100vw;
    height: 100vh;
  }

  .drawer__coco__close {
    right: -100vw;
  }
}
/*fin movil*/

@keyframes open_my_tools {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}
