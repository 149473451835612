.custom__flashcard__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 40px;
}

.button-open-selector-languages {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 25px;
  background-color: transparent;
}

.open-languages-icon {
  width: 30px;
  height: 30px;
}

.custom__flashcard__title {
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  animation-name: AnimationShowCards;
  animation-duration: 1s;
  margin-bottom: 5%;
}

.add__flashcard__mazo__main {
  display: flex;
}

.search__image__custom__main {
  margin-top: 20px;
  color: #eaeaee;
}

#custom-flashcard-front {
  border-radius: 10px;
  background: black;
  width: 241.75px;
  height: 46vh;
  max-height: 400px;

  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  text-align: center;
  align-items: center;
  justify-content: center;

  background-image: url('/images/Flashcards/Background.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;

  caret-color: #ff5562;

  animation-name: AnimationShowCards;
  animation-duration: 2s;
}

#custom-flashcard-front textarea {
  width: 100%;
  border: none;
  border-radius: 10px;
  margin: 0px;
  padding: 10px;
  max-height: 400px;
  overflow-y: scroll;

  /* Texto */
  background: linear-gradient(45deg, #ff5962 20%, #fd935e 100%);
  -webkit-background-clip: text;
  color: transparent;
  line-height: 24px;
  font-size: large;
}

#custom-flashcard-front textarea::placeholder {
  background: linear-gradient(45deg, #ff5962 20%, #fd935e 100%);
  -webkit-background-clip: text;
  color: transparent;
}

#custom-flashcard-back textarea::placeholder {
  color: #4f4f4f;
}

#custom-flashcard-back {
  position: relative;
  border-radius: 10px;
  background: white;
  width: 241.75px;
  height: 46vh;
  max-height: 400px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  align-items: center;
  justify-content: center;
  text-align: center;

  caret-color: #4f4f4f;

  animation-name: AnimationShowCards;
  animation-duration: 3s;
}

#custom-flashcard-back textarea {
  width: 100%;
  border: none;
  border-radius: 10px;
  margin: 0px;
  padding: 10px;
  max-height: 340.79px;
  overflow-y: scroll;

  /* Texto */
  color: #4f4f4f;
  line-height: 24px;
  text-align: center;
  font-size: large;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

#custom-flashcard-back textarea:focus {
  border: none;
  outline: none;
  outline-width: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

*:focus {
  outline: none;
}

.search__images__list {
  width: 310px;
  height: 310px;
  display: flex;
  flex-flow: wrap;
  overflow: auto;
}

.title-traductor-flashcard {
  margin-top: 15px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #38516b;
}

/* buscador de imagenes */

input {
  outline: none;
}

input[type='search-custom-flashcard'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-size: 100%;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type='search-custom-flashcard'] {
  margin-bottom: 20px;
  background: #27384a url('IconsPlusButton/search.svg') no-repeat 9px center;
  border: 0px;
  padding: 5px 25% 5px 45px;
  /* width: 350px; */

  font-weight: 300;
  font-size: 18px;
  line-height: 16px;

  color: #467db8;

  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

input[type='search-custom-flashcard']:-moz-placeholder {
  color: #467db8;
}

input[type='search-custom-flashcard']::-webkit-input-placeholder {
  color: #467db8;
}

.personalizar-flashcard {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contenedor-btn-traductor {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contenedor-btn-traductor-group button {
  margin-bottom: -10px;
}

.contenedor-btn-traductor-group .title-traductor {
  font-size: 15px;
  line-height: 18px;
  color: #38516b;
}

.contenedor-btn-send-flashcard {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 20px;
  /* margin-right: 75px; */
}

.btn-send-flashcard {
  border-radius: 7px;
  border-color: #ff5962;
  color: #ffffff;
}

.btn-send-flashcard:hover {
  color: #ffffff;
  background: linear-gradient(192.11deg, #ff5962 16.06%, #fd935e 91.7%);
}

.add-frases-success-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5%;
}

.add-frases-success-animation {
  background-image: no-repeat;
  background-image: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100% !important;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .add__flashcard__mazo__main {
    flex-direction: column;
    align-items: center;
  }

  .search__images__list {
    width: auto;
    height: auto;
    overflow: visible;
  }
}

/* transiciones y efectos */
@keyframes AnimationShowCards {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}