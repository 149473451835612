#alerta-de-nota-agregada {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	width: 304px;
	height: 304px;

	background: #141d26;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	font-weight: normal;
	font-size: 22px;
	color: #ffffff;
}

#msg-nota-agregada {
	font-weight: normal;
	font-size: 22px;
	line-height: 26px;
	cursor: pointer;
	color: #ffffff;
}
