.responses__other__users__main {
  margin: 15px 0 0 0;
}

#output-item {
  font-weight: 500;
  font-size: 27px;
  line-height: 32px;
  color: #ffffff;
}

#respuesta-del-usuario {
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  text-align: justify;
}

#cantidad-de-likes {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  text-transform: capitalize;
}

#cantidad-de-comentarios {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  text-transform: capitalize;
}

#oracion-de-respuesta {
  display: inline;
  position: relative;
}

#oracion-de-respuesta:hover {
  text-decoration: underline;
  cursor: pointer;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .responses__other__users__main {
    margin: 15px 30px 0 30px;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .responses__other__users__main {
    margin: 15px 30px 0 30px;
  }
}
/*fin movil*/
