.contenedor-listado-emojis {
	position: absolute;
	top: -250px;
	right: 0;
	left: 0;
	background-color: #537895;
	background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 15px;
	padding-bottom: 0;
	min-width: 310px;
	height: 250px;
	color: white;
}

.listado-emojis {
	margin-top: 5px;
	display: flex;
	flex-wrap: wrap;
	height: 85%;
	width: 100%;

	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.emogi-item {
	width: 25px;
	height: 25px;
	transition: 0.5s all;
	cursor: pointer;
	margin: 6px;
	font-size: 25px;
}

.emogi-item:hover {
	transform: scale(1.4);
}

.buscardor-emojis {
	height: 25px;
}

.buscador-emojis-text {
	width: 100%;
	background: transparent;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: white;
	border: 0;
	border-bottom: 1px solid rgba(84, 82, 82, 0.687);
	padding-bottom: 5px;
}

.buscador-emojis-text::placeholder {
	color: white;
}
