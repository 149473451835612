.selector-tipos-conversaciones {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-inline: 10px;
}

.selector-tipos-conversaciones-btn {
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #6c859f;
  cursor: pointer;
  transition: 1s all;
}

.tipos-conversaciones-activa {
  color: #ffffff;
}

@media (max-width: 750px) {
  .selector-tipos-conversaciones {
    margin-inline: 0;
  }

  .selector-tipos-conversaciones-btn {
    font-size: 18px;
    line-height: 20px;
  }
}
