.contenedor-peculiaridades-multiselect {
	position: relative;
}

.lista-peculiaridades-multiselect {
	position: absolute;
	z-index: 3000;
	width: 110px;
	height: 12vh;

	background: #267dff;
	background: black;
	border-radius: 7px;
	margin-top: -16px;
	margin-left: 2%;

	background: #141d26;
	border-radius: 10px;
}

.peculiaridades-listado {
	list-style: none;
	height: 100%;
	overflow-y: scroll;
}

.peculiaridades-listado::-webkit-scrollbar {
	width: 5px;
}

.peculiaridades-listado::-webkit-scrollbar-thumb {
	background: rgba(153, 151, 151, 0.774);
	border-radius: 7px;
}

.peculiaridades-listado::-webkit-scrollbar-thumb:hover {
	background: rgba(172, 169, 169, 0.774);
}

.peculiaridades-listado::-webkit-scrollbar-track {
	background: rgba(165, 161, 161, 0.021);
}

.peculiaridades-listado::-webkit-scrollbar-track:hover {
	background: rgba(165, 161, 161, 0.514);
}

.peculiaridades-listado::-webkit-scrollbar {
	display: block;
}

.peculiaridades-multiselect-item {
	font-weight: 300;
	font-size: 8px;
	line-height: 12px;
	color: #ffffff;
}

.peculiaridades-multiselect-item:hover {
	color: rgb(224, 220, 220);
	cursor: pointer;
}

.peculiaridades-multiselect-body {
	padding: 5%;
	height: 83%;
}

.peculiaridades-multiselect-footer {
	text-align: center;
	cursor: pointer;
	font-size: 13px;
	font-weight: 500;
}

.btn-peculiaridades {
	padding-top: 2px;
	padding-bottom: 1.2px;
	padding-left: 3px;
	padding-right: 3px;
	margin-left: 1px;
	margin-right: 1px;
	box-sizing: border-box;
	border-radius: 7px;
	font-size: 9px;
	line-height: 10px;
	text-align: center;
	background-color: transparent;
	color: #ffffff;
	text-transform: uppercase;
}

.btn-peculiaridades:focus {
	outline: none;
}

/* peluliaridad slang */
.btn-slang {
	border: 0.7px solid #37b0ff;
}

.btn-slang-active {
	background-color: #37b0ff;
}

.btn-slang:hover {
	background-color: #37b0ff;
}

/* peculiaridad vulgar */
.btn-vulgar {
	border: 0.7px solid #7d3298;
}

.btn-vulgar-active {
	background-color: #7d3298;
}

.btn-vulgar:hover {
	background-color: #7d3298;
}

/* supervicencia */
.btn-supervivencia {
	border: 0.7px solid #e4b422;
}

.btn-supervivencia-active {
	background-color: #e4b422;
}

.btn-supervivencia:hover {
	background-color: #e4b422;
}

/* formal */
.btn-formal {
	border: 0.7px solid #54c8ba;
}

.btn-formal-active {
	background-color: #54c8ba;
}

.btn-formal:hover {
	background-color: #54c8ba;
}

/* formal */
.btn-extraño {
	border: 0.7px solid #bf7eff;
}

.btn-extraño-active {
	background-color: #bf7eff;
}

.btn-extraño:hover {
	background-color: #bf7eff;
}

/* region */
.btn-region {
	border: 0.7px solid #267dff;
}

.btn-region-active {
	background-color: #267dff;
}

.btn-region:hover {
	background-color: #267dff;
}

.region-icon {
	font-size: 10px;
	line-height: 0px;
	transform: rotate(190deg);
}

/* nota-especial */
.btn-nota-especial {
	border: 0.7px solid #fe835f;
}

.btn-nota-especial-active {
	background-color: #fe835f;
}

.btn-nota-especial:hover {
	background-color: #fe835f;
}

.btn-nota-especial span {
	font-size: 11px;
	line-height: 0px;
}

/* anticuado */
.btn-anticuado {
	border: 0.7px solid #ff7e9d;
}

.btn-anticuado-active {
	background-color: #ff7e9d;
}

.btn-anticuado:hover {
	background-color: #ff7e9d;
}

/* colores de otros tags que se agregan despues a la base de datos, seran asignados aleatorio */

.btn-verde {
	border: 0.7px solid rgb(31, 173, 26);
}

.btn-verde-active {
	background-color: rgb(31, 173, 26);
}

.btn-verde:hover {
	background-color: rgb(31, 173, 26);
}

.btn-rojo {
	border: 0.7px solid rgb(143, 21, 21);
}

.btn-rojo-active {
	background-color: rgb(143, 21, 21);
}

.btn-rojo:hover {
	background-color: rgb(143, 21, 21);
}

.btn-gris {
	border: 0.7px solid rgba(165, 161, 161, 0.514);
}

.btn-gris-active {
	background-color: rgba(165, 161, 161, 0.514);
}

.btn-gris:hover {
	background-color: rgba(165, 161, 161, 0.514);
}

.btn-rosybrown {
	border: 0.7px solid rosybrown;
}

.btn-rosybrown-active {
	background-color: rosybrown;
}

.btn-rosybrown:hover {
	background-color: rosybrown;
}

.btn-seagreen {
	border: 0.7px solid seagreen;
}

.btn-seagreen-active {
	background-color: seagreen;
}

.btn-seagreen:hover {
	background-color: seagreen;
}

.btn-tomato {
	border: 0.7px solid tomato;
}

.btn-tomato-active {
	background-color: tomato;
}

.btn-tomato:hover {
	background-color: tomato;
}

.btn-turquoise {
	border: 0.7px solid turquoise;
}

.btn-turquoise-active {
	background-color: turquoise;
}

.btn-turquoise:hover {
	background-color: turquoise;
}

/* Styles for old versions of IE */
.nota-especial-palpitando {
	background-color: rgb(63, 119, 192);
	box-shadow: 0px 0px 2px 1px rgb(94, 132, 182);
	width: 14px;
	height: 14px;
	margin: 0;
	border-radius: 50px;
	margin-left: 5px;
}

/* :not(:required) hides this rule from IE9 and below */
.nota-especial-palpitando:not(:required) {
	-webkit-animation: nota-especial-palpitando 1300ms ease 0s infinite normal;
	-khtml-animation: nota-especial-palpitando 1300ms ease 0s infinite normal;
	-moz-animation: nota-especial-palpitando 1300ms ease 0s infinite normal;
	-ms-animation: nota-especial-palpitando 1300ms ease 0s infinite normal;
	-o-animation: nota-especial-palpitando 1300ms ease 0s infinite normal;
	animation: nota-especial-palpitando 1300ms ease 0s infinite normal;
	/* display: inline-block; */
	text-indent: -9999px;
	-moz-transform: rotate(45deg) scale(1);
	-ms-transform: rotate(45deg) scale(1);
	-webkit-transform: rotate(45deg) scale(1);
	transform: rotate(45deg) scale(1);
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

@-webkit-keyframes nota-especial-palpitando {
	0% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
	14% {
		-moz-transform: rotate(45deg) scale(1.3);
		-ms-transform: rotate(45deg) scale(1.3);
		-webkit-transform: rotate(45deg) scale(1.3);
		transform: rotate(45deg) scale(1.3);
	}
	28% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
	42% {
		-moz-transform: rotate(45deg) scale(1.3);
		-ms-transform: rotate(45deg) scale(1.3);
		-webkit-transform: rotate(45deg) scale(1.3);
		transform: rotate(45deg) scale(1.3);
	}
	70% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
}
@-moz-keyframes nota-especial-palpitando {
	0% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
	14% {
		-moz-transform: rotate(45deg) scale(1.3);
		-ms-transform: rotate(45deg) scale(1.3);
		-webkit-transform: rotate(45deg) scale(1.3);
		transform: rotate(45deg) scale(1.3);
	}
	28% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
	42% {
		-moz-transform: rotate(45deg) scale(1.3);
		-ms-transform: rotate(45deg) scale(1.3);
		-webkit-transform: rotate(45deg) scale(1.3);
		transform: rotate(45deg) scale(1.3);
	}
	70% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
}

@-o-keyframes nota-especial-palpitando {
	0% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
	14% {
		-moz-transform: rotate(45deg) scale(1.3);
		-ms-transform: rotate(45deg) scale(1.3);
		-webkit-transform: rotate(45deg) scale(1.3);
		transform: rotate(45deg) scale(1.3);
	}
	28% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
	42% {
		-moz-transform: rotate(45deg) scale(1.3);
		-ms-transform: rotate(45deg) scale(1.3);
		-webkit-transform: rotate(45deg) scale(1.3);
		transform: rotate(45deg) scale(1.3);
	}
	70% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
}
@keyframes nota-especial-palpitando {
	0% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
	14% {
		-moz-transform: rotate(45deg) scale(1.3);
		-ms-transform: rotate(45deg) scale(1.3);
		-webkit-transform: rotate(45deg) scale(1.3);
		transform: rotate(45deg) scale(1.3);
	}
	28% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
	42% {
		-moz-transform: rotate(45deg) scale(1.3);
		-ms-transform: rotate(45deg) scale(1.3);
		-webkit-transform: rotate(45deg) scale(1.3);
		transform: rotate(45deg) scale(1.3);
	}
	70% {
		-moz-transform: rotate(45deg) scale(1);
		-ms-transform: rotate(45deg) scale(1);
		-webkit-transform: rotate(45deg) scale(1);
		transform: rotate(45deg) scale(1);
	}
}
