.suscription__success__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.view__request__success_starts {
  background-image: url("../Icons/starts.svg");
  background-size: auto;
  min-width: 340px;
  height: 120px;
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;
  padding-top: 30px;
  text-align: center;
  color: #ffffff;
}

.suscription__success__title__container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 30%;
}

.suscription__success__title {
  font-weight: 500;
  font-size: 50px;
  color: #1b2734;
  height: 30%;
  /* stars */
  background-image: url("../Icons/starts.svg");
  width: 310px;
  height: 120px;
  padding: 30px;
}

.suscription__success__text {
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #1b2734;
}
.suscription__success__text__date {
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #7894b4;
}
.suscription__success__button {
  width: 100%;
  height: 66px;
  background: #19d537;
  border-radius: 7px;
  font-weight: 500;
  font-size: 25px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
