.contenedor-notificaciones {
  position: relative;
}

.buzon {
  position: relative;
}

.buzon:hover {
  cursor: pointer;
}

.alerta-notificacion {
  position: absolute;
  bottom: 70%;
  left: 60%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #cb0404;
  color: white;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificaciones {
  position: fixed;
  right: 70px;
  top: 70px;
  height: 70vh;
  width: 420px;
  background: #212f3f;
  border-radius: 4px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.notificaciones:before {
  content: '';
  position: fixed;
  right: 80px;
  top: 62px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #212f3f;
}

.notif-triangle {
  position: absolute;
}

.notifications-title {
  background: -webkit-linear-gradient(190.73deg, #ff5562 12.28%, #fd9d5d 91.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 17px;
  text-align: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 3px;
}

.notifications-content {
  position: relative;
  /* top: 5.6%; */
  flex: 1;
}
.una-notificacion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  border-bottom: 2px solid #141f2c;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.una-notificacion:hover {
  background: #141d27;
  border-radius: 8px;
}

.notif-mensaje {
  left: 8%;
  width: 100%;

  display: -webkit-box;
  margin-left: 10px;
  margin-right: 10px;

  color: white;
  font-size: 15px;

  line-height: 1.2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.not-read {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #ff1212;
  position: absolute;
  right: 0;
}

.notification-close {
  position: fixed;
  left: 25px;
  top: 15px;

  display: none;
}

.notifications__loading__fetch {
  display: flex;

  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}

@media (max-width: 680px) {
  .notificaciones {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
  }

  .notificaciones:before {
    display: none;
  }

  .notification-close {
    display: block;
  }
}
