.trial-card {
  width: 481px;
  height: 655px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 42px;
  padding-bottom: 42px;
}

.card__pay__container {
  width: 100%;
  height: 100%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 15px;
}

.card__pay__header {
  background: #1b2734;
  border-radius: 15px 15px 0 0;
  min-height: 150px;
  position: relative;
}

.card__pay__header__close__modal {
  position: absolute;
  right: 9px;
  top: 9px;
  transition: 1s;
  cursor: pointer;
}

.card__pay__header__close__modal:hover {
  transform: scale(1.2);
}

.card__pay__header__title {
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 20px;
  margin-left: 72px;
  margin-right: 72px;
}

.card__pay__header__emogi {
  margin-top: 38px;
  margin-bottom: 5px;
}

.card__pay__timer_text {
  font-weight: 300;
  font-size: 60px;
  background: linear-gradient(263.54deg, #c031ff 0%, #0791ff 95.35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card__pay__timer_title {
  line-height: 30px;
  text-align: center;
  color: #1b2734;
}

.card__pay__body__text {
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #1b2734;
  padding-left: 20px;
  padding-right: 20px;
}

.card__pay__button__pay {
  min-width: 296.07px;
  height: 75.78px;

  background: #267dff;
  border-radius: 47px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
}

.card__pay__button__pay:hover {
  background: #2675ec;
}

.card__pay__button__omitir {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #267dff;
  margin: 20px;
  cursor: pointer;
}
