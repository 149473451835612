.editor {
  position: relative;
  width: 75vw;
  min-height: 40vh;
  background: #0f161d;
  border-radius: 7px;

  box-sizing: border-box;
  border: none;
  cursor: text;
  text-align: justify;

  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  color: white;
  padding: 15px;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

@media (max-width: 700px) {
  .editor {
    width: 100vw;
  }
}
