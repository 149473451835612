.new-conversation-form {
  /* para que los emojis aparescan bien */
  position: relative;
  width: 100%;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: flex-end;
  padding-right: 10px;
}

.new-conversation-form-docuchat {
  background: #0b1622;
}

.new-conversation-form-minichat {
  background-color: transparent;
  padding-bottom: 10px;
  padding-top: 8px;
}

.formulario-chat {
  display: flex;
  align-items: flex-end;
  width: 100%;
  min-height: 45px;
}

.first-message {
  background-color: aqua;
}

.formulario-minichat {
  background: #0b1622;
  border-radius: 37px;
  margin-right: 10px;
  margin-left: 10px;
}

.text-area-send-message::-webkit-search-decoration,
.text-area-send-message::-webkit-search-cancel-button {
  display: none;
}

.text-area-send-message {
  -webkit-appearance: textfield;
  font-size: 100%;
  outline: none;
  width: 100%;
  height: max-content;
  max-height: 300px;
  min-height: 50px;
  background: transparent;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  padding: 10px;
  padding-top: 15px;
  color: #6c859f;
  display: block;
}

.text-area-send-message-border-docuchat {
  border-width: 1px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-color: #6c859f;
}

.text-area-send-message-border-minichat {
  border: none;
  max-height: 200px;
  padding-left: 0;
}

.text-area-send-message:-moz-placeholder {
  color: #6c859f;
}

.text-area-send-message::-webkit-input-placeholder {
  color: #6c859f;
}

.message-container {
  position: relative;
}

.face-emogis-chat {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 4px;
  width: 32px;
  cursor: pointer;
  transition: 0.5s all;
}

.face-emogis-chat:hover {
  transform: scale(1.2);
}

.spiner-send-message-audio-form {
  margin-bottom: 8px;
  margin-right: 15px;
}

.btn-send-message-audio-form {
  background-color: transparent;
  transition: 0.5s all;
  border-radius: 50%;
}

.btn-send-message-audio-form:hover {
  transform: scale(1.1);
}

.btn-send-message-audio-form img {
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: 0.5s all;
  margin-right: 15px;
}

.btn-send-image-upload-form {
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: 0.5s all;
  margin-right: 10px;
  margin-bottom: 10px;
}

.btn-send-image-upload-form img {
  cursor: pointer;
  width: 32px;
  height: 32px;
  transition: 0.5s all;
}

.btn-send-image-upload-form:hover {
  transform: scale(1.1);
}

.file-uploader-image input[type='file'] {
  display: none;
}

.common-message-typing_participants-container {
  position: relative;
}

.common-message-typing_participants {
  font-weight: 300;
  font-size: 13px;
  color: #6c859f;
  background-color: #0b1622c7;
  padding: 5px;
  font-weight: bold;
  border-radius: 10px;
  /* text-transform: capitalize; */

  position: absolute;
  top: -32px;
  left: 15px;
}

.message-cited-container {
  position: relative;
  background: #0b1622;
  padding: 7px;
  word-wrap: break-word;
  padding: 15px;
}

.message-cited-user-name {
  margin: 0;
  margin-bottom: 10px;
  color: #7eadf3;
  font-weight: 300;
}

.message-cited-text {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;

  line-height: 1.3;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #ffffff;
  white-space: pre-line;
}

.message-cited-btn-close {
  background-color: transparent;
  font-size: 17px;
  line-height: 20px;
  color: rgb(241, 88, 88);
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: 15px;
  text-transform: uppercase;
}