#form-define-thread {
  width: 681px;
  min-height: 350px;
  max-height: 492px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
}

#form-define-thread-back {
  background-color: transparent;
  padding-left: 15px;
  padding-top: 8px;
}

#form-define-thread-user-to-define {
  width: 462px;
  min-height: 350px;
  max-height: 492px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  padding-top: 15px;
}

#form-define-thread-question {
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #ffffff;
  margin-top: 20px;
}

#form-define-thread #form-thread-container {
  font-size: 20px;
  line-height: 23px;
  text-align: justify;
  color: #ffffff;
  background: #1b2734;
  border-radius: 10px;
}

#form-thread-title-conversation {
  padding-left: 15px;
  padding-top: 15px;
}

#form-define-thread #comment-or-synopsis {
  width: 100%;
  height: 100%;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  background: transparent;
  border: none;
  resize: none;
  color: white;
}

#form-define-thread #comment-or-synopsi::placeholder {
  color: #6182a6;
}

.btn-outline-linear-gradient-define {
  width: 136px;
  height: 41px;
  text-align: center;
  border-radius: 7px;
  display: flex;
}

.btn-outline-linear-gradient-define span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  /*border-radius: 7px;*/
  flex: 1;
  margin: 1px;
  transition: box-shadow 0.5s;
  border-radius: 7px;
  box-shadow: inset 0 0 0 40px #141d26;
}

.btn-outline-linear-gradient-define:hover span {
  box-shadow: inset 0 0 0 40px transparent;
  cursor: pointer;
}

#btn-outline-define {
  background: linear-gradient(213.59deg, #ff5962 16.06%, #fd935e 91.7%);
}
