body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: linear-gradient(180deg, #1b2734 5.11%, #000000 100%) !important;*/
  background: linear-gradient(180deg, #151f29 1.37%, #000000 100%) !important;
  line-height: 1.1;
  min-height: 100vh;
  min-width: 100vw;
}

/* Usa esto globalmente donde se pueda reportar/verificar una linea (de una flashcard, una frase en el diccionario,
 una linea de output, o una linea de input - todas son lineas).
  Este es estilo temporal.*/
.report-line {
  color: red;
}

.report-line:hover {
  cursor: pointer;
}

button {
  border: 0;
  outline: none !important;
  outline-width: 0 !important;
}

/*  todos los botones se debe quitar el border al focus */
button:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-transparent {
  border: none;
  background-color: transparent;
}

* {
  margin: 0px;
  padding: 0px;
}

.click-me {
  cursor: pointer;
}

.black {
  color: black !important;
}

.bold {
  font-weight: bold;
}

.white {
  color: #ffffff;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.orange-gradient {
  background: linear-gradient(191.16deg, #fd9d5d 0%, #ff5562 100%);
}

.orange-text-gradient {
  background: -webkit-linear-gradient(182.95deg, #ff5962 16.06%, #fd935e 91.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scroll-coco {
  height: 100%;
  overflow: auto;
  padding: 2%;
}

/* scroll personalizado */

.scroll-coco::-webkit-scrollbar {
  width: 6px;
  height: 0;
  display: block;
}

.scroll-coco::-webkit-scrollbar-thumb {
  background: rgba(255, 253, 253, 0.061);
  border-radius: 100px;
}

.scroll-coco::-webkit-scrollbar-thumb:hover {
  background: rgba(250, 247, 247, 0.292);
}

.scroll-coco::-webkit-scrollbar-track {
  background: rgba(122, 121, 121, 0.068);
}

.scroll-coco::-webkit-scrollbar-track:hover {
  background: rgba(248, 244, 244, 0.027);
  border-radius: 7px;
}

/* fin scroll personalizado */

.scroll {
  max-height: calc(40vh - 2rem);
  overflow-y: auto;
}

.scroll-layout-main {
  overflow-y: auto;
  height: auto;
}

.loading-coco-full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

/* BUTTONS */

.linear-gradient-button {
  border: solid 1px transparent;
  border-radius: 7px;
  background-image: linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #0c1218 inset;

  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linear-gradient-button:hover {
  box-shadow: none;
  color: white;
  cursor: pointer;
}

.loading-coco-center {
  height: calc(100vh - 280px);
}

/*Button outline primary*/
/*TODO: Convertir a un componente*/

.btn-orange-gradient-linear-outline {
  border-radius: 7px;
  border: solid 1px transparent;

  background-image: linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%);

  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #1b2734 inset;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: normal;
  font-size: medium;
  line-height: 23px;
  text-align: center;

  color: #ffffff;
}

.btn-orange-gradient-linear-outline:hover {
  box-shadow: none;
  color: white;
  cursor: pointer;
}

.truncar__text__line {
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* ANIMACION DE VOLTEO DE FLASHCARD */

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: white;
  height: 100%;
}

/* Style the back side */
.flip-card-back {
  background-color: black;
  color: white;
  transform: rotateY(180deg);
  height: 100%;
}

/* CONTENEDORES PARA MEDIATEXT, FLASHCARD (BOX LIGTH) */
.coco__cardbox__container {
  display: flex;
  justify-content: center;
  height: calc(100vh - 275px);
  min-height: 200px;
}

/* nuevo */
.coco__cardbox__card {
  position: relative;
  background: #1b2734;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  width: 90%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

/* TEXTAREAS */
.textarea-gray {
  width: 100%;

  background: #0d131a;
  border-radius: 7px;
  color: white;
  border: none;
  resize: none;
  font-size: medium;
}

.textarea-gray::placeholder {
  background: #0d131a;
  border: none;

  font-weight: normal;
  font-size: medium;
  color: #6c859f;
}

/* INPUT TEXT */
.input-text-gray {
  width: 100%;

  background: #0d131a;
  border-radius: 7px;
  color: white;
  border: none;
  resize: none;
  font-size: medium;
}

.input-text-gray::placeholder {
  background: #0d131a;
  border: none;

  font-weight: normal;
  font-size: medium;
  color: #6c859f;
}

/* COLORES DE FUENTES */
.orange-color {
  color: #fd955e;
  font-size: large;
  line-height: 24px;
}

/* CLASES PARA LAS FLASHCARDS */

.overflow-scroll-gradient {
  position: relative;
  height: 200px;
  overflow-y: scroll;
}

.orange-gradient-color {
  background: linear-gradient(45deg, #ff5962 20%, #fd935e 100%);
  -webkit-background-clip: text;
  color: transparent;
  line-height: 24px;
  text-align: center;
  font-size: large;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.gray-color {
  color: #4f4f4f;
  line-height: 24px;
  text-align: center;
  font-size: large;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .coco__cardbox__container {
    height: calc(100vh - 315px);
    padding-left: 7px;
    padding-right: 7px;
  }

  .coco__cardbox__card {
    width: 100%;
    height: 100%;
  }

  .scroll-coco::-webkit-scrollbar {
    display: none;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .coco__cardbox__container {
    height: calc(100vh - 315px);
    padding-left: 7px;
    padding-right: 7px;
  }

  .coco__cardbox__card {
    width: 100%;
    height: 100%;
  }

  .scroll-coco::-webkit-scrollbar {
    display: none;
  }
}

/*fin movil*/

/*Escritorio*/
/* @media (min-width: 1199.98px) {} */
/*Fin Escritorio*/

/*Tablet*/
/*Portrait*/
/* @media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {} */

/*landscape*/
/* @media (min-width: 991.98px) and (max-width: 1199.98px) and (orientation: landscape) {} */
/*fin tablet*/

/*movil*/
/*Portrait*/
/*Default*/
/* @media (max-width: 575.98px) and (orientation: portrait) {} */
/*landscape*/
/* @media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {} */
/*fin movil*/
