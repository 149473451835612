.container-user-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -15px;
  left: 40px;
  width: 162px;
  height: 63px;
  z-index: 100;

  background: darkorchid;
  border-radius: 5px;

  font-weight: normal;
  font-size: medium;
  text-align: start;
}

.label-user-tooltip {
  color: #81b5ee;
}

.user-name-tooltip {
  color: #ffffff;
}

.tooltip-direction {
  position: absolute;
  bottom: 50%;
  left: -6px;
  width: 12px;
  height: 12px;

  background: darkorchid;
  transform: rotate(-45deg);
}
