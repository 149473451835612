.splash-screen-main {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.splash-screen-contenido {
	text-align: center;
}

.splash-screen-logo {
	width: 300px;
	height: 300px;
}
