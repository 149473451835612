.setting__notifications__box {
  border: 1px solid #7894b4;
  box-sizing: border-box;
  border-radius: 15px;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: 5%;
  margin-right: 5%;
}

.notification__option__main {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #7894b4;
  padding: 27px;
}

.notification__option__texts {
  width: 100%;
}

.notification__option__texts h2 {
  font-weight: 500;
  font-size: 19px;
  line-height: 30px;
  color: #7894b4;
  margin: 0 0 6px 0;
}
.notification__option__texts p {
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  color: #7894b4;
  margin: 0;
}

/* para el switch  */
.toggle__notification__main {
  position: relative;
  background: rgba(120, 148, 180, 0.3);
  border-radius: 29px;

  height: 30px;
  max-width: 75px;
  min-width: 75px;
  cursor: pointer;
}

.toggle__notification__toggle__status {
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #7894b4;
  margin-right: 17px;
  margin-left: 17px;
}

.toggle__notification__main input {
  display: none;
}

.toggle__notification__slider {
  width: 48px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  padding-right: -10px;
  margin-left: -9px;
}

.toggle__notification__slider:before {
  position: absolute;
  content: "";
  min-width: 23px;
  max-width: 23px;
  height: 24px;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  border-radius: 29px;
  background-color: #267dff;
  margin: 3px;
}

.toggle__notification__slider__inactive {
  position: absolute;
  content: "";
  width: 23px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 29px;
  background: #7894b4;
  opacity: 0.5;
  margin: 3px;
}

.toggle__notification__slider__inactive:before {
  content: "";
  width: 23px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  border-radius: 29px;
  background-color: #267dff;
  margin: 3px;
}

input:checked + .toggle__notification__slider__inactive:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

input:checked + .toggle__notification__slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}
