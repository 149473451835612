.topbar__nav__plusbutton {
  display: flex;
  justify-content: space-between;
  height: 120px;
  align-items: center;
}

.plus__button__main {
  width: 100%;
  margin-bottom: 25px;
}

.plus__button__main::-webkit-scrollbar {
  display: none;
}

.topbar-nav-plusbutton-logo {
  width: 88.62px;
  margin-left: 18px;
}

.plus__logo__coco__custom {
  width: 88px;
  margin-top: 0;
}

.btn__plus__button__container {
  display: flex;
  padding: 20px;
  overflow: auto;
}

.btn__plus__button__container button {
  margin-left: 10px;
  margin-right: 10px;
  background: #1b2734;
  min-width: 183.73px;
  height: 45.06px;
  border-radius: 10px;
  color: #ffffff;
  box-shadow: 0.2em 0.1em 0.3em #121312, -0.1em -0.2em 0.4em #2e3f53;
  font-size: x-large;

  display: flex;
  justify-content: center;
  align-items: center;
}

/*.btn__plus__button__container button:hover,*/
.btn-plus-button-active {
  background: #1e2936;
  background: linear-gradient(45deg, #ff5962 20%, #fd935e 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: x-large;
}

.btn__plus__button__container button:focus {
  box-shadow: 0.2em 0.1em 0.3em #121312, -0.1em -0.2em 0.4em #2e3f53;
}

.btn__plus__button__container button img {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

.plus__button__close__button {
  transform: rotate(45deg);
  border-radius: 50%;
  cursor: pointer;
}

.contendor-plusbutton-traductor {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 40px;
}

.contendor-mensaje-flashcard {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contendor-mensaje-flashcard-item,
.contendor-mensaje-flashcard-traductor {
  background: linear-gradient(360deg, #28394c 0%, #010102 100%);
  border-radius: 9px;
  height: auto;
  padding-bottom: 5%;
  padding-right: 5%;
  padding-left: 5%;
  text-align: start;
}

.contendor-mensaje-flashcard-item {
  width: 70vw;
  max-height: calc(100vh - 150px);
}

.contendor-mensaje-flashcard-traductor {
  width: 55vw;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .plus__button__close__button {
    width: 50px;
  }

  .plus__logo__coco__custom {
    width: 50px;
  }

  .btn__plus__button__container button {
    min-width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 35.06px;
    font-size: 15px;
  }

  .contendor-mensaje-flashcard-item {
    width: 100vw;
    max-height: auto;

    padding-bottom: 3px;
    padding-right: 3px;
    padding-left: 3px;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .plus__button__close__button {
    width: 50px;
  }

  .plus__logo__coco__custom {
    width: 50px;
  }

  .contendor-mensaje-flashcard-item {
    width: 100vw;
    max-height: auto;
  }
}

/*fin movil*/