/* buscador de imagenes */

input {
  outline: none;
}

input[type='search-mediatext'] {
  -webkit-appearance: textfield;
  font-size: 100%;
  outline: none;
  min-width: 50px;
  max-width: 270px;
  width: 100%;
  transition: 1s all;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type='search-mediatext'] {
  opacity: 0.9;
  background: #ffffff url('../Icons/SearchIcon.svg') no-repeat 15px center;
  border: 0px;
  padding: 9px 0px 10px 50px;
  height: 35px;
  font-size: 19px;
  color: #4f4f4f;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

input[type='search-mediatext']:-moz-placeholder {
  color: #4f4f4f;
}
input[type='search-mediatext']::-webkit-input-placeholder {
  color: #4f4f4f;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  input[type='search-mediatext'] {
    max-width: 100%;
    width: 100%;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  input[type='search-mediatext'] {
    max-width: 100%;
    width: 100%;
  }
}
/*fin movil*/
