.leftbar__navigation__main,
.leftbar__navigation__mini {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh);
  border-radius: 0px 0px 0px 0px;
  padding-bottom: 50px;
  transition: 0.5s all;
  background: #1b2734;
  position: relative;
}

.leftbar__navigation__main {
  width: 136px;
  min-width: 136px;
  max-width: 136px;
}

.leftbar__navigation__mini {
  width: 69px;
  min-width: 69px;
  max-width: 69px;
}

.leftbar__logo__coco {
  width: 105px;
  height: 88.53px;
  margin-top: 28px;
  transition: 0.5s all;
}

.leftbar__navigation__rigth__icon {
  position: absolute;
  right: -26px;
  top: 82px;
  background: #23303d;
  border-radius: 0px 7px 7px 0px;
  width: 26px;
  height: 25px;
}

.leftbar__navigation__rigth__icon:hover {
  background: #2c3d4e;
}

.leftbar__navigation__menu__container {
  margin-top: 35px;
  cursor: pointer;
  width: 26px;
  height: 25px;
  background: transparent;
  padding: 7px;
}

.leftbar__navigation__menu__container:hover
  .leftbar__navigation__amburger__icon {
  display: none;
}

.leftbar__navigation__menu__container:hover
  .leftbar__navigation__amburger__icon__rigth {
  display: block;
}

.leftbar__navigation__amburger__icon__rigth {
  display: none;
}

.leftbar__navigation__scrolling {
  height: 100%;
  overflow: auto;
  text-align: center;
}

.leftbar__progresos__diarios {
  margin-top: 76.48px;
}

.leftbar__nav__links,
.leftbar__nav__links__mini {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  margin-top: 5px;
}

.leftbar__nav__links__mini span {
  background: lightpink;
  display: none;
  transition: 1s all;
}

.leftbar__nav__links:hover,
.leftbar__nav__links__mini:hover {
  color: white;
  text-decoration: none;
  background: #304862;
  border-radius: 0 5px 5px 0;
}

@media (max-height: 651px) {
  .leftbar__progresos__diarios {
    overflow-y: scroll;
  }
}
