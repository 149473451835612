.topbar__nav {
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  background: transparent;
  overflow-y: auto;
  background-color: #1b2734af;
  padding-left: 35px;
  padding-right: 25px;

  position: absolute;
  z-index: 1;
  width: calc(100vw - 135px);
  transform: 0.5s all;
}

.topbar__nav__min {
  width: calc(100vw - 69px);
}

.topbar__nav__mobile {
  width: calc(100vw);
}

.topbar__nav__min__logo {
  width: 50px;
  margin-right: 20px;
}

.topbard-content-left {
  display: flex;
  align-items: center;
  margin-right: 15px;
  width: 100%;
}

.topbar-nav-controls {
  display: flex;
  align-items: center;
}

.topbar-nav-controls-item {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
}
#font-icon-community {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-left: 15px;
  margin-right: 25px;
  color: #ffffff;
  cursor: pointer;
}

#link-community {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

#link-community span {
  margin-left: 5px;
}

/* dejar solo iconos */
@media (max-width: 900px) {
  #font-icon-community span {
    display: none;
  }

  #font-icon-community {
    width: 28px;
    margin: 0;
    margin-right: 15px;
  }

  .topbar-nav::-webkit-scrollbar {
    display: none;
  }
}
