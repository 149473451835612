.languages-top-bar {
  display: flex;
  align-items: center;
}

.language-item-top-bar {
  position: relative;
  background: #737373;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.language-item-top-bar-title {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.language-top-bar-active {
  background: #267dff;
}

.badge-Language-item {
  position: absolute;
  bottom: 70%;
  left: 60%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ff5562;
  color: white;
  font-size: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1.5px solid #141e28; */
}
