.calendar__main {
  border-radius: 30px;
  background: #25374b;
  width: 400px;
  height: 500px;

  padding-top: 55px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calendar__body__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar__body {
  width: 300px;
  margin: 0 2rem;
  display: flex;
  flex-wrap: wrap;
  color: white;
}

.btn-outline-linear-gradient-calendar {
  width: 180px;
  height: 47px;
  text-align: center;
  border-radius: 7px;
  display: flex;
}

.btn-outline-linear-gradient-calendar span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  border-radius: 7px;
  flex: 1;
  margin: 1px;
  transition: box-shadow 0.5s;
  box-shadow: inset 0 0 0 40px #25374b;
}

.btn-outline-linear-gradient-calendar:hover span {
  box-shadow: inset 0 0 0 40px transparent;
  cursor: pointer;
}

#btn-outline-calendar {
  background: linear-gradient(46.35deg, #ff5562 14.22%, #fd9d5d 90.1%);
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .calendar__main {
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    overflow: scroll;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .calendar__main {
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: scroll;
  }
}
/*fin movil*/
