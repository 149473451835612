#btn-waveform {
  color: white;
  border: none;
  border-radius: 100%;
  outline: none;

  width: 32px;
  height: 32px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: #267dff;
}

#waveform wave {
  z-index: 0;
}

#waveform wave wave {
  z-index: 2;
}
