.botton-selector-dark {
	width: 30px;
	height: 30px;
	background-color: transparent;
}

.botton-selector-ligth {
	width: 30px;
	height: 30px;
	background-color: transparent;
}

.open-languages-icon {
	width: 25px;
	height: 25px;
}

.language-active-text {
	font-size: 18px;
	line-height: 23px;
	color: #ffffff;
	margin-right: 5px;
	margin-left: 15px;
}
