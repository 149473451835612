#diccionary-coco {
  position: fixed;
  max-width: 500px;
  width: 500px;
  height: calc(100% - 72px);
  right: 0;
  top: 0;
  background: #1b2734;
  border-radius: 0px 0px 0px 15px;
  box-shadow: 5px 5px 10px 0px #080808;
  z-index: 10;
  margin-bottom: 20px;
}

.diccionary-open {
  -webkit-animation: open_diccionary 0.3s;
  /* Fx 5+ */
  -moz-animation: open_diccionary 0.3s;
  /* Opera 12+ */
  -o-animation: open_diccionary 0.3s;
  /* IE 10+, Fx 29+ */
  animation: open_diccionary 0.3s;
}

.main-diccionary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 20px 0px 20px 20px;
}

.diccionary-header {
  width: 100%;
}

.diccionary-body {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  flex: 1;
  padding-bottom: 30px;
  padding-top: 30px;
}

/*Styles del buscador*/

.search-diccionary-container {
  height: 48px;
  max-width: 440px;
  width: 440px;
  background: #273753;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 33px;
  display: flex;

  margin: 0px 10px 30px 10px;
  padding: 15px;
  margin-right: 20px;
}

.search-diccionary-icon {
  width: 20px;
  margin-right: 10px;
}

.search-diccionary-input {
  width: 100%;
  background-color: transparent;
  border: 0;
  color: white;
  text-align: start;
}

.search-diccionary-input::placeholder {
  color: rgba(255, 255, 255, 0.776);
  text-align: start;
}

/*Hasta aquí terminan los styles del buscador*/

.header-diccionary-close {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -39px;
}

.searched-word {
  font-weight: bold;
  font-size: x-large;
  color: #ffffff;
  margin: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.searched-word-traduccion-directa {
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 5px;
  color: #81b5ee;
  font-size: 20px;
}

.section-oxford {
  height: 0;
  border: 1px solid #81b5ee;
  flex: 1;
}

.oxford-phrases {
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #81b5ee;
}

.text-initial {
  font-weight: normal;
  font-size: large;
  text-align: center;
  color: #ffffff;
}

.diccionary__headers__buttons {
  display: flex;
  justify-content: space-around;
  max-width: 450px;
  width: 450px;
  padding: 2px;
  /* azul piel */

  background: rgb(76, 104, 128);
  border-radius: 12px;
}

.btn__header__dictionary {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  opacity: 0.75;
  background: transparent;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  min-width: 140px;
}

.btn__header__dictionary__active,
.btn__header__dictionary:hover {
  background: linear-gradient(240.67deg, #86a7ef 9.84%, #a8ccff 71.7%);
  font-weight: bold;
}

.diccionary__headers__lemma {
  font-weight: bold;
  font-size: x-large;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.diccionary__headers__translations__text {
  color: darkorange;
  margin-top: 5px;
  margin-bottom: 5px;
}

@keyframes open_diccionary {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  #diccionary-coco {
    width: 100vw;
    height: 100vh;
    z-index: 10010;
  }

  .header-diccionary-close {
    position: relative;
    left: 0;
    margin: 0;
  }

  .main-diccionary {
    padding-top: 0;
  }

  .search-diccionary-container {
    max-width: auto;
    width: auto;
  }

  .diccionary__headers__buttons {
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);
  }

  .btn__header__dictionary {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    min-width: auto;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  #diccionary-coco {
    width: 100vw;
    height: 100vh;
    z-index: 10010;
  }

  .header-diccionary-close {
    position: relative;
    left: 0;
    margin: 0;
  }

  .main-diccionary {
    padding-top: 0;
  }

  .search-diccionary-container {
    max-width: auto;
    width: auto;
  }

  .diccionary__headers__buttons {
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);
  }

  .btn__header__dictionary {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    min-width: auto;
  }
}
/*fin movil*/
