.mini-chat-principal-fixed {
  position: fixed;
  bottom: 70px;
  right: 100px;

  -webkit-animation: open_chat 0.3s;
  /* Fx 5+ */
  -moz-animation: open_chat 0.3s;
  /* Opera 12+ */
  -o-animation: open_chat 0.3s;
  /* IE 10+, Fx 29+ */
  animation: open_chat 0.3s;
  z-index: 1000;
}

.mini-chat-content {
  display: flex;
  flex-direction: column;
  background: #1b2734;
  border-radius: 10px 10px 0px 0px;
  width: 450px;
  height: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mini-chat-options-conversation {
  position: absolute;
  top: 32px;
  right: -57px;
  display: flex;
  flex-direction: column;
}

.minichat__btn__container {
  display: flex;
  flex-direction: column;
}

.mini-chat-btn-float {
  width: 50px;
  height: 50px;

  background: #1b2734;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  color: #ffffff;
}

.mini-chat-btn-float img {
  width: 25px;
  height: 25px;
}

.mini-chat-btn-pedir-ayuda-float {
  width: 50px;
  height: 50px;
  background: #1b2734;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-chat-btn-pedir-ayuda-float img {
  width: 60px !important;
  height: 60px;
}

.mini-chat-content-firt-screen {
  padding: 10px;
}

.contenedor-btn-hilos {
  position: fixed;
  bottom: 70px;
  right: 100px;
  width: 180px;
  height: 35px;
  background: #16202b;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.contenedor-btn-hilos:hover {
  background: rgb(18, 26, 34);
}

.btn-hilos-float {
  padding: 10px;
  color: #ffffff;
  font-size: 15px;
  line-height: 16px;
  width: auto;
}

.btn-hilos-counts {
  min-height: 22px;
  min-width: 22px;
  background: #ff5562;
  border-radius: 50px;
  padding: 4px;
  color: #fff;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* header del mini chat */
.header-mini-chat-container {
  display: flex;
  justify-content: space-between;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #0d1824;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.header-mini-chat-back-title {
  width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
}

.header-mini-chat-btn {
  background-color: transparent;
  margin-right: 10px;
}

.mini-chat-form-send-message {
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
  border-radius: 35px;
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .mini-chat-principal-fixed {
    bottom: 105px;
    right: 80px;
  }
}

/*landscape*/
@media (min-width: 991.98px) and (max-width: 1199.98px) and (orientation: landscape) {
  .mini-chat-principal-fixed {
    bottom: 105px;
    right: 80px;
  }
}
/*fin tablet*/

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .mini-chat-principal-fixed {
    top: 85px;
    right: 0px;
    left: 0px;
    bottom: 105px;
  }

  .contenedor-chat-close {
    visibility: hidden;
  }

  .mini-chat-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .contenedor-btn-hilos {
    right: 10px;
  }

  .mini-chat-options-conversation {
    position: absolute;
    top: 45px;
    right: 10px;
    z-index: 10;
  }

  .minichat__btn__container {
    background-color: rgba(54, 78, 103, 0.988);
    padding: 4px;
    border-radius: 7px;
  }

  .mini-chat-btn-float,
  .mini-chat-btn-pedir-ayuda-float {
    width: 40px;
    height: 40px;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .mini-chat-principal-fixed {
    top: 85px;
    right: 0px;
    left: 0px;
    bottom: 105px;
  }

  .contenedor-chat-close {
    visibility: hidden;
  }

  .mini-chat-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .contenedor-btn-hilos {
    right: 10px;
  }

  .mini-chat-options-conversation {
    position: absolute;
    top: 45px;
    right: 10px;
    z-index: 10;
  }

  .minichat__btn__container {
    background-color: rgba(54, 78, 103, 0.988);
    padding: 4px;
    border-radius: 7px;
  }

  .mini-chat-btn-float,
  .mini-chat-btn-pedir-ayuda-float {
    width: 40px;
    height: 40px;
  }
}
/*fin movil*/
