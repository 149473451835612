.selection-context-menu {
  position: absolute;

  background: #0C1723;
  border: #0C1723 solid 1px;
  border-radius: 10px;

  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  cursor: default;

  color: #FFFFFF;

  box-shadow: 8px 8px 10px -6px black, -8px 8px 10px -6px black;
  z-index: 1000000;
}

.icon-selection-context-menu {
  width: 30px;
  display: inline-flex;
  justify-content: center;
}

.context-menu-triangle {
  position: absolute;
  left: calc(-11.21px);
  top: calc(-11.21px + 28.28px);

  width: 20px;
  height: 20px;

  background: #0C1723;
  box-shadow: 0px 8px 10px -6px black;

  transform: rotate(45deg);
}

.context-menu-action {
  display: flex;
  align-items: center;
}

.context-menu-action:hover {
  background: #101F30;
  border-radius: 9px;
}