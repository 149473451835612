[tooltip] {
	position: relative;
}

[tooltip]::before {
	content: "";
	position: absolute;
	top: -6px;
	left: 50%;
	transform: translateX(-50%);
	border-width: 4px 6px 0 6px;
	border-style: solid;
	border-color: #ffca42 transparent transparent transparent;
	z-index: 100;
	opacity: 0;
}

[tooltip]::after {
	content: attr(tooltip);
	position: absolute;
	left: 50%;
	top: -6px;
	transform: translateX(-50%) translateY(-100%);
	background: #ffca42;
	text-align: center;
	color: #121a23;
	padding: 5px;
	font-size: 12px;
	min-width: 100px;
	border-radius: 7px;
	pointer-events: none;
	opacity: 0;
	visibility: visible !important;
	display: block;
}

[tooltip]:hover::after,
[tooltip]:hover::before {
	opacity: 1;
}

[toltip-position="top"]::before {
	left: 50%;
}

[toltip-position="top"]::after {
	left: 50%;
}
[toltip-position="left"]::before {
	left: 0%;
	top: 50%;
	margin-left: -12px;
	transform: translatey(-50%) rotate(-90deg);
}

[toltip-position="buttom"]::before {
	top: 100%;
	margin-top: 8px;
	transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}
[toltip-position="right"]::before {
	left: 100%;
	top: 50%;
	margin-left: 0px;
	transform: translatey(-50%) rotate(90deg);
}
[toltip-position="left"]::after {
	left: 0%;
	top: 50%;
	margin-left: -8px;
	transform: translateX(-100%) translateY(-50%);
}

[toltip-position="buttom"]::after {
	top: 100%;
	margin-top: 8px;
	transform: translateX(-50%) translateY(0%);
}
[toltip-position="right"]::after {
	left: 100%;
	top: 50%;
	margin-left: 8px;
	transform: translateX(0%) translateY(-50%);
}
