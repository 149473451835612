.notification-badge-small {
	max-width: 7px;
	max-height: 7px;
	min-width: 7px;
	min-height: 7px;
	border-radius: 50%;
	background-color: #ff6061;
}

.notification-badge-big {
	background: #ff6061;
	max-width: 17px;
	max-height: 17px;
	min-width: 17px;
	min-height: 17px;
	border-radius: 50%;
}

.notification-badge-whit-number {
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: #ff6061;
	color: white;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.notification-badge-number {
	font-weight: 500;
	font-size: 10px;
	color: #ffffff;
	margin-left: -1.5px;
}
