.contenedor-nota-especial {
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
	height: 80%;
	width: 100%;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;

	background: #141d26;
	border-radius: 10px;
	padding: 10px;
}

.nota-especial-text {
	background-color: transparent;
	border: none;
	width: 100%;
	height: 100%;
	color: #ffffff;
	max-height: 70%;
	font-size: 15px;
	line-height: 18px;
	color: #6182a6;
  resize: none;
}

.nota-especial-text::placeholder {
	color: #6182a6;
}

.nota-especial-footer {
	position: absolute;
	cursor: pointer;
	padding-top: 1px;
	font-size: 15px;
	line-height: 18px;
	bottom: 8px;
	right: 8px;
}
