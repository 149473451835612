.modal {
  width: 100vw;
  height: 100vh;
  /* La posisión que le da Bootstrap a esta clase es absolute, pero el div se mueve junto con el layout y la parte baja queda sin capa así que le puse fixed */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;

  display: flex;
  justify-content: center;
  text-align: center;
}

.modal-dialog {
  overflow-x: scroll;
  z-index: 1000100;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80vw;
  /* TEMPORAL. Hasta que se resuelva el problema de que no se muestra el contenido. */
}

.modal-content {
  position: relative;
  background: transparent;
  border-radius: 9px;
}

.output-modal {
  width: 876px;
  height: 664px;
  background: #1b2734;
  /* Back 1 */
  background: #1f2c3a;
}

.media-text-modal {
  width: 70vw;
  height: 80vh;
  background: #1f2c3a;
  box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.25);
}

.modal-crear-playlist {
  width: 940px;
  height: auto;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.4);
  border-radius: 9px;
  background-color: #1b2734;
}

.modal-report {
  background-color: #1b2734;
  width: 391px;
  height: 395px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.modal-diccionary {
  width: 700px;
  height: auto;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.4);
  border-radius: 9px;
  background-color: #1b2734;
  text-align: start;
}

.modal-perfil {
  text-align: start;
  width: auto;
  height: 600px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  background-color: #1b2734;
}

.notification-flashcards {
  width: 883px;
  height: 74vh;
  border-radius: 9px;
  background-color: #1b2734;
  position: fixed;
  overflow: scroll;
}

.notification-user-output-comments {
  width: 54vw;
  height: 77vh;
  background-color: #1b2734;
  position: fixed;
  overflow: scroll;
  padding: 32.69px;
}

.notification-user-output-corrections {
  width: 80vw;
  height: 83vh;
  padding-top: 14px;
  background-color: #1b2734;
  position: fixed;
  overflow: scroll;
}

.notification-media-texts {
  width: 1000px;
  height: 550px;
  border-radius: 9px;
  background-color: #141d27;
  overflow: scroll;
}

.modal-de-frecuencia {
  text-align: start;
  width: 400px;
  height: auto;
  background-color: #141d27;
  padding-left: 2%;
  padding-right: 2%;
}

.output-confirmation {
  width: 80vw;
  background: #1b2734;
  border-radius: 7px;
}

.modal-tutorial {
  width: 650px;
  height: auto;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.4);
  border-radius: 9px;
  background-color: #1b2734;
}

.modal-new-thread {
  width: 600px;
  min-height: 45vh;
  border-radius: 9px;
  background-color: #1b2734;
  position: fixed;
  overflow: scroll;
}

.modal-procesar-items {
  min-width: 400px;
  height: 55vh;
}

.modal-nota-agregada {
  width: 304px;
  height: 304px;

  background: #141d26;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.modal-item-comment {
  width: 40vw;
  height: 40vh;

  /*background: #25374B;*/
  background: #1b2734;
  border-radius: 7px;
}

.modal-delete-item {
  width: 527px;
  height: 438px;

  background: #1b2734;
  border-radius: 7px;
}

.free-dictionary {
  width: 876px;
  height: 664px;
  background: #1b2734;
  background: #1f2c3a;
}

.modal-confirm-delete {
  width: 437px;
  height: 293px;

  background: #25374b;
  border-radius: 7px;
}

.modal-define-thread {
  background: #141d26;
  border-radius: 7px;
}

.modal-new-thread {
  background: #141d26;
  border-radius: 7px;
}

.modal-share-tracks {
  width: auto;
  height: auto;
  min-width: 500px;
}

.warning-alert {
  width: auto;
  height: auto;
}

.delete-alert {
  width: 406px;
  height: 257px;
  background: #213040;
  border-radius: 7px;
}

.alert {
  width: 406px;
  height: 257px;
  background: #213040;
  border-radius: 7px;
}

.item-verification {
  width: 100vw;
  height: 100vh;
  min-height: 713px;
  background: #1b2734;
  border-radius: 7px;
}

.item-preview-hilo {
  width: 50vw;
  height: 80vh;
  background: #1b2734;
  border-radius: 22px;
  text-align: start;
}

.snippet {
  width: 681px;
  background: #141d26;
  border-radius: 7px;
}

.modal-galley-images {
  width: 95vw;
  height: 95vh;
}

.modal-tutoriales-outputs-mediatexs {
  width: auto;
  height: auto;
  max-width: 96vw;
}

.pay-confirmation_preview {
  width: 481px;
  max-height: 700px;
}

.pay-confirmation {
  min-width: 50vw;
  max-height: 99vh;
  overflow: auto;
}

.spotify-conect-form {
  overflow: auto;
  background: #141d26;
  border-radius: 10px;
  width: 527px;
  height: 438px;
}

.calendario_modal {
  width: 400px;
  height: auto;
}

.modal__setting__acount {
  width: 100vw;
  height: 100vh;
  text-align: start;
}

.modal__full__version__mobile {
  width: 100vw;
  max-height: 100vh;
  background: #151f29;
  overflow: auto;
  padding-bottom: 50px;
}

.modal__full__version__mobile::-webkit-scrollbar {
  display: none;
}

@media (max-width: 800px) {
  .item-verification {
    width: 100vw;
    height: 100vw;
  }

  .output-confirmation {
    width: 100vw;
    /* max-width: 100vw;
    max-height: 100vh; */
  }

  .item-preview-hilo {
    width: 95vw;
  }

  .modal-dialog {
    width: 100vw;
    height: 100vh;
  }

  .modal-galley-images {
    width: 100vw;
    height: 100vh;
  }

  .pay-confirmation_preview {
    width: auto;
    height: auto;
  }

  .pay-confirmation {
    width: 100vw;
    max-height: 100vh;
    overflow: auto;
  }

  .modal-tutoriales-outputs-mediatexs {
    height: 100vh;
    width: 100vw;
    overflow: scroll;
  }
}

@media (max-height: 700px) {
  .pay-confirmation_preview {
    height: 98vh;
    overflow: scroll;
  }

  .modal-tutoriales-outputs-mediatexs {
    height: 100vh;
    overflow: scroll;
  }
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .modal__full__version__mobile {
    width: 80vw;
    height: auto;
  }
}

/*landscape*/
@media (min-width: 991.98px) and (max-width: 1199.98px) and (orientation: landscape) {
  .modal__full__version__mobile {
    width: 70vw;
    height: auto;
  }
}

/*fin tablet*/
