
.seleccionar-peculiaridades {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.peculiaridad-icono {
    width: 10px;
    height: 10px;
    margin-top: 1px;
}

.peculiaridad-icono-region {
    margin-top: 3.2px;
    width: 8px;
    min-height: 8px;
}

.peculiaridades-text {
    margin-left: 2px;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    cursor: pointer;
}

.peculiaridades-region-text {
    margin-left: 3px;
    margin-bottom: 4px;
    font-size: 11px;
    line-height: 12px;
    color: #FFFFFF;
}