.container-spotify {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.container-spotify-enlazar {
  display: flex;
  justify-content: center;
}

.container-spotify-text {
  padding-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
  color: white;
}
