.textarea-gray {
  width: 100%;

  background: #0d131a;
  border-radius: 7px;
  color: white;
  border: none;
  resize: none;
  font-size: medium;
}

.textarea-gray::placeholder {
  background: #0d131a;
  border: none;

  font-weight: normal;
  font-size: medium;
  color: #6c859f;
}
