.settings__payment__suscription__main {
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 43px;
}

.settings__payment__suscription__description {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #7894b4;

  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #7894b4;
  margin-top: 92px;
}

.settings__payment__suscription__table__title {
  font-weight: bold;
  font-size: 15px;
  line-height: 30px;
  color: #7894b4;
  margin-top: 37px;
}

.suscription__grid__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  border: 1px solid #7894b4;
  border-radius: 7px;
  box-sizing: border-box;

  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #7894b4;
}

.grid__one {
  grid-column: 1 / 2;
  padding: 18px;
}
.grid__two {
  grid-column: 2 / 4;
  padding: 18px;
}
.grid__three {
  grid-column: 1;
  padding: 18px;
}
.grid__four {
  grid-column: 2;
  padding: 18px;
}
.grid__four img {
  margin-right: 8px;
}

.grid__five {
  grid-column: 3;
  padding: 18px;
}

.grid__border {
  grid-column: 1 / 4;
  border-bottom: 1px solid #7894b4;
}

.grid__two,
.grid__four,
.grid__five {
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;

  color: #1b2734;
}

.settings__payment__cancel__container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 150px;
}

.settings__payment__cancel__suscription {
  background-color: transparent;
  font-size: 20px;
  color: #1b2734;
}

.settings__payment__cancel__suscription img {
  margin-right: 7px;
  margin-top: -3px;
}
