.card__message__success {
  background: linear-gradient(360deg, #28394c 0%, #010102 100%);
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.4);
  border-radius: 9px;
  min-height: calc(100vh - 300px);
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card__message__success__cohete {
  width: 75%;
  height: 75%;
}

.title-message {
  font-size: 30px;
  font-weight: 600;
  background: -webkit-linear-gradient(#ff5962 16.06%, #fd935e 91.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.btn-go {
  width: 200px;
  border-radius: 21px;
  color: #ffffff;
  border-color: #ff5962;
  color: #ffffff;
}

.btn-go:hover {
  background: linear-gradient(192.11deg, #ff5962 16.06%, #fd935e 91.7%);
  color: #ffffff;
}

.progress-output {
  display: inline;
}

#c-btns-message-success {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 20px;
}
