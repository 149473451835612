.contenedor-notificaciones-inputs {
  min-height: 90%;
}

.notificaciones-inputs-titulo {
  margin-top: 50px;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;

  color: #ffffff;
}

.contenedor-notificaciones-inputs-tracks {
  display: flex;
  flex-flow: wrap;
  align-items: space-around;
  justify-content: center;
  overflow-y: scroll;
  overflow-y: scroll;
  margin: 10px;
}

.notificaciones-inputs-track-item {
  width: 250px;
  margin: 15px;
}
