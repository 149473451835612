* {
  padding: 0px;
  margin: 0px;
}

.layout__general__main {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  height: calc(100vh);
}

.logo {
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.logo div img {
  width: 100%;
  max-height: 70px;
}

.flecha {
  float: right;
  transition: all 0.3s;
}

.subboton {
  padding: 0px;
  margin-left: 35px;
  list-style: none;
  height: 0px;
  overflow: hidden;
  transition: all 0.3s;
  max-height: 100%;
}

.subboton:hover {
  height: 60px;
}

.layout__general__panelD {
  width: 100%;
  height: calc(100vh);
  overflow: auto;
}

.layout__general__main__content {
  margin-top: 80px;
  width: 100%;
  padding: 3%;
}

header {
  height: 70px;
  width: 100%;
  background: #1b2734;

  position: static;
  top: 0px;
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.perfil {
  max-height: 50px;
  float: right;
  width: 50px;
  padding: 10px;
}

.negrita {
  color: #fcc728;
}

.formularios {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 425px) {
  .layout__general__main nav ul li {
    font-size: 0.7em;
    font-weight: 300;
    margin-left: 2px;
    padding: 10px;
  }

  .flecha {
    font-size: 0.6em;
  }
}

.logo-coco {
  width: 80px;
  margin: 0;
}

.circle-1-dashboard img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .layout__general__main__content {
    padding: 0px;
    margin-top: 105px;
  }

  .layout__general__mobile__margin {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .layout__general__panelD::-webkit-scrollbar {
    display: none;
  }
}

/*landscape*/
@media (min-width: 991.98px) and (max-width: 1199.98px) and (orientation: landscape) {
  .layout__general__main__content {
    padding: 0px;
    margin-top: 105px;
  }

  .layout__general__mobile__margin {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .layout__general__panelD::-webkit-scrollbar {
    display: none;
  }
}
/*fin tablet*/

/* TODO esta es una prueba de css */
/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .layout__general__main__content {
    padding: 0px;
    margin-top: 105px;
  }

  .layout__general__mobile__margin {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .layout__general__panelD::-webkit-scrollbar {
    display: none;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .layout__general__main__content {
    padding: 0px;
    margin-top: 105px;
  }

  .layout__general__mobile__margin {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .layout__general__panelD::-webkit-scrollbar {
    display: none;
  }
}
/*fin movil*/
