.span-hover{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span-hover:hover{
  border-radius: 100%;
  background: rgba(11, 22, 34, 0.6);
}