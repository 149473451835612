.select-languages-container {
	position: relative;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.select-languages-title {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 15%;
	text-align: center;
	font-weight: 500;
	font-size: 18px;
	color: #6c859f;
	margin-left: 10px;
}

.select-languages-list {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	width: 100%;
	height: 85%;
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 10%;
	overflow-y: auto;
}

.language-item {
	display: flex;
	align-items: center;
	height: 35px;
	cursor: pointer;
}

.language-item-icon {
	width: 25px;
	height: 25px;
	margin-right: 8px;
}

.language-item-title {
	font-size: 18px;
	line-height: 30px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.language-item-title-dark {
	color: white;
}
.language-item-title-ligth {
	color: #6c859f;
}
