.contenedor-traductor-general {
  display: flex;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
}

.contenedor-traductor-frase,
.contenedor-traductor-result {
  width: 40vw;
  height: auto;
  background: #0c1218;
}

.contenedor-traductor-result {
  height: 30vh;
  background: #151f29;
}

.contenedor-traductor-header {
  display: flex;
  margin: 19px;
}

.traductor-header-idioma-activo {
  width: 50%;
  display: flex;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}

.traductor-header-idioma-traductor {
  display: flex;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 54px;
}

.traductor-header-cambiar-idioma {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.contenedor-traductor-traducir {
  display: flex;
  align-items: center;
  margin-right: -43px;
  margin-left: -45px;
  z-index: 1;
}

.contenedor-traductor-text-area,
.contenedor-traductor-text-area-result {
  width: calc(100% - 50px);
  height: calc(100% - 100px);
  border: none;
  overflow-y: scroll;
  display: block;

  margin-left: 20px;

  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  background-color: transparent;
  color: #b3a5a7;
}

.contenedor-traductor-text-area-result {
  padding-top: 20px;
  margin-left: 40px;
}

.contenedor-frases-ejemplos {
  text-align: start;
  width: 50%;
  height: 33vh;
  margin-top: 10px;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .contenedor-traductor-frase,
  .contenedor-traductor-result {
    width: auto;
    height: auto;
  }

  .contenedor-traductor-general {
    height: 60vh;
    min-height: 300px;
  }

  .contenedor-frases-ejemplos {
    width: 100%;
    height: auto;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .contenedor-traductor-frase,
  .contenedor-traductor-result {
    width: auto;
    height: auto;
  }

  .contenedor-traductor-general {
    height: 60vh;
    min-height: 300px;
  }

  .contenedor-frases-ejemplos {
    width: 100%;
    height: auto;
  }
}
/*fin movil*/
