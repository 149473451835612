.contenedor-chat-comunidad-padding {
  margin-bottom: -35vh;
  padding-left: 3%;
  padding-right: 3%;
}

.contenedor-chat-comunidad {
  display: flex;
  height: calc(100vh - 250px);
  width: 100%;
  min-width: 720px;
  background: #1b2734;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  margin-bottom: 30vh;
}

.contenedor-chat-close {
  display: flex;
  justify-content: flex-end;
  margin-top: -25px;
}

.options-chat-buttons {
  background: #1b2734;
  border-radius: 7px;
  margin-bottom: 2px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 0px 8px 0 rgba(0, 0, 0, 0.19);
}

.button-chat-close {
  background: #1b2734;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  padding: 6px;
  margin-bottom: 1px;
  transition: 1s all;
}

.button-chat-close:hover {
  transform: scale(1.2);
}

.contenedor-participants-on-conversation {
  margin-left: 20px;
  display: flex;
}

.photo-participants-on-conversation-default {
  background: #151f29;
  border-radius: 50%;
}

.photo-participants-on-conversation {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #151f29;
  margin-right: 2px;
  margin-left: 2px;
}

.lista-de-hilos {
  position: relative;
  width: 30%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #0d1824;
}

.contenedor-hilos-item,
.conversation-is-selected {
  border-radius: 10px;
  max-height: 55px;
  display: block;
}

.contenedor-hilos-item {
  cursor: pointer;
}

.contenedor-hilos-item-teacher {
  max-height: 60px;
  border-bottom: 0.5px #6c859f solid;
  border-radius: 0;
  margin-bottom: 5px;
}

.contenedor-hilos-item-teacher:hover {
  border-radius: 10px;
  border-bottom: 0;
}

.conversation-is-selected {
  background: rgb(11, 22, 34);
  border-bottom: 0;
  border-radius: 10px;
}

.contenedor-hilos-item:hover {
  background: rgba(11, 22, 34, 0.51);
}

.conversation-item-teacher-icon {
  margin: 5px 0 0 5px;
  width: 35px;
}

.conversationsList {
  position: relative;
  width: 70%;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

.conversation-list-hidden:hover {
  cursor: pointer;
}

.chat-canal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  max-height: 70px;

  border-bottom: 1px solid #0d1824;
}

.mis-hilos-header {
  font-weight: bold;
  font-size: 30px;
  color: #6c859f;
}

.chat-conversation-title {
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;

  word-break: break-all;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
}

.btn-expandir-chat {
  margin: 15px;
  background: transparent;
  border: 0;
}

.btn-expandir-chat:focus {
  outline: none;
}

.btn-expandir-chat:hover {
  transition: 1s all;
  transform: scale(1.2);
}

.subir-imagen {
  cursor: pointer;
}

.subir-imagen img {
  width: 35px;
}

.btn-itemize-conversation {
  position: absolute;
  right: 5px;
  top: -25px;
  color: rgb(231, 241, 79);
  cursor: pointer;
}

.input-enviar-chat {
  margin-right: 10px;
  margin-left: 10px;
  height: 35px;
  padding: 15px;
  width: 90%;
  color: rgb(59, 107, 158);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border: 0;

  background: #0b1622;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
}

input-enviar-chat::placeholder {
  font-weight: normal;
  font-size: 20px;

  color: #6c859f;
}

.chat-email-user {
  font-weight: 500;
  color: rgb(12, 12, 12);
  font-size: 12px;
  margin: 0;
  margin-top: -4px;
}

.common-message {
  display: inline-block;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 500px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: #3f5165;
  border-radius: 46px;
  list-style: none; /*quita puntitos a li*/
}

.common-message time {
  position: absolute;
  margin-right: 20px;
  margin-top: -25px;
  bottom: 10px;
  right: 5px;
  font-weight: 300;
  font-size: 12px;
  line-height: 13px;
  color: #90b1d5;
}

.common-message .status {
  float: right;
  text-align: end;
}

.chat-imagen-content {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 46px;
  border-bottom-right-radius: 46px;
  margin-bottom: 15px;
  cursor: pointer;
}

.common-message.is-you {
  align-self: flex-end;
  background: #0b1622;
  min-width: 200px;
}

.common-message.is-time {
  text-decoration: none;
  align-self: center;
  background-color: rgb(7, 7, 7);
  color: rgba(224, 220, 220, 0.88);
  font-size: 20px;
  text-transform: uppercase;
}

.common-message.is-time::before {
  display: none;
}

/* scroll para chats */

.scroll-chat::-webkit-scrollbar {
  width: 10px;
}

.scroll-chat::-webkit-scrollbar-thumb {
  background: rgba(85, 84, 84, 0.212);
  border-radius: 7px;
}

.scroll-chat::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 84, 84);
}

.scroll-chat::-webkit-scrollbar-track {
  background: rgba(165, 161, 161, 0.021);
}

.scroll-chat::-webkit-scrollbar-track:hover {
  background: rgba(165, 161, 161, 0.068);
}

.scroll-chat::-webkit-scrollbar {
  display: block;
}

#btn-new-thread {
  cursor: pointer;
}

#btn-new-thread #btn-img-new-thread {
  width: 38px;
  height: 38px;
  min-width: 38px;
  min-height: 38px;
  border-radius: 100%;

  background: #3f5165;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

#btn-new-thread #btn-title-new-thread {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #6c859f;
}

/**/

input[type='message-search'] {
  -webkit-appearance: textfield;
  font-size: 100%;
  outline: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type='message-search'] {
  width: 100%;
  opacity: 0.9;
  background: #0b1622 url('./Icons/IconMessageSearch.svg') no-repeat 15px center;
  border: 0px;
  padding: 9px 0px 12px 34px;
  height: 35px;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  /*border-radius: 30px;*/

  font-weight: normal;
  font-size: 15px;
  color: white;
}

input[type='message-search']:-moz-placeholder {
  color: #6c859f;
}
input[type='message-search']::-webkit-input-placeholder {
  color: #6c859f;
}

/*Button Define Thread*/

.btn-outline-linear-gradient-define-thread {
  width: 108px;
  height: 100%;

  text-align: center;
  border-radius: 7px;
  display: flex;
}

.btn-outline-linear-gradient-define-thread span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  /*border-radius: 7px;*/
  flex: 1;
  margin: 1px;
  transition: box-shadow 0.5s;
  border-radius: 7px;
  box-shadow: inset 0 0 0 40px #1b2734;
}

.btn-outline-linear-gradient-define-thread:hover span {
  box-shadow: inset 0 0 0 40px transparent;
  cursor: pointer;
}

#btn-outline-define-thread {
  background: linear-gradient(213.59deg, #ff5962 16.06%, #fd935e 91.7%);
}

#cerrar-docuchat {
  background: #1b2734;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}

.create-conversation-camera-container {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.create-conversation-camera-icon {
  width: 154px;
  height: 154px;
  background: #161f29;
  border-radius: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 1s all;
  cursor: pointer;

  background-size: 100 100%;
  background-position: center;
  background-size: cover;
}

.create-conversation-camera-icon:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

.create-conversation-camera-icon p {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #4d739b;
  margin-top: 5px;
  margin-bottom: 3px;
}

.create-conversation-name-group {
  font-size: 20px;
  line-height: 23px;
  color: #4d739b;
  background-color: transparent;
  border: 0;
  border-bottom: 1.5px solid #324860;
  padding: 10px;
  margin-bottom: 130px;
  margin-top: 15px;
  width: 100%;
}

.create-conversation-name-group::placeholder {
  color: #4d739b;
}

.btn-create-group-container {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}
