.topbar__navigation__main {
  height: 50px;
  border-top: 2px solid #151f29;

  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: scroll;
}

.topbar__navigation__navlink {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  min-width: 38px;
  min-height: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
}
