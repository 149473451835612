#foto-del-comentarista {
  position: relative;
  display: inline-block;
  margin: 5px;
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: 100% 100%;
}

#foto-del-comentarista:hover {
  cursor: pointer;
}

#nombre-del-comentarista {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  height: 20px;
  /* left: 1220px;*/
  top: 0;
  left: 16px;
  background: #267dff;
  border-radius: 14px;
  text-transform: capitalize;
  position: absolute;
  visibility: hidden;

  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}

#input-comentario {
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  height: 51px;
  background: #000000;
  border: none;
  border-radius: 10px;

  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}

#input-comentario::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #919191;
}

#input-comentario:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #919191;
}

#input-comentario::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #919191;
}

.cursor-pointer {
  cursor: pointer;
}

#comentario-del-usuario {
  padding: 10px 20px;
  display: inline-block;

  background: #000000;
  border-radius: 20px;

  font-weight: 300;
  font-size: 15px;
  line-height: 18px;

  color: #ffffff;
}

/* #area-de-comentarios {
  overflow-y: scroll;
  max-height: calc(98vh - 420px);
  height: calc(98vh - 420px);
} */

#fecha-del-comentario {
  margin-left: 9px;
  font-weight: 300;
  font-size: 12px;
  color: #a3a1a1;
}

.loading-comments {
  padding-left: 25px;
  padding-bottom: 5px;
}
