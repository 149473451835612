.buttons__mobile__float {
  position: fixed;
  right: 0;
  bottom: 130px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all;
  z-index: 1001;
}

.button__mobile__item__main {
  position: relative;
  background-color: transparent;
  width: 75px;
  margin-top: -10px;
}

.button__mobile__item__main img {
  width: 100%;
}

.button__mobile__item {
  width: 50px;
  height: 50px;

  background: #1b2734;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button__mobile__item img {
  width: 60%;
  height: 60%;
}

.button__mobile__item__chat {
  width: 25px !important;
  margin-left: 5px;
}
