.alert__trial__container {
  height: 50px;
  background: linear-gradient(263.54deg, #c031ff 0%, #0791ff 95.35%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 48px;
  padding-right: 30px;
}

.alert__trial__text {
  font-size: 20px;
  color: #ffffff;
  margin: 0;
}

.alert__trial__button {
  background-color: transparent;
  font-size: 25px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
}
