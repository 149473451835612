.header {
  background-color: #fff;
}

.Ellipse {
  position: absolute;
  left: 84.34%;
  right: 10.33%;
  top: 17.59%;
  bottom: 22.5%;
}

.boxShadow {
  background: #ffffff;
  box-shadow: 0px 7px 30px rgba(73, 35, 35, 0.25);
  border-radius: 20px !important;
}

.boxShadow-square {
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(73, 35, 35, 0.25);
}

.cardSmall {
  max-width: 18rem;
}

.color-dark {
  background-color: #282c34;
}

.letra-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: rgb(85, 83, 83);
}

/* botones redondos */
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  border: 2px solid #dee4ee;
}

.btn-circle.btn-medium {
  width: 42px;
  height: 42px;
  padding: 5px 10px;
  font-size: 20px;
  line-height: 1.33;
  border-radius: 20px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 0px 10px;
  font-size: 25px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.animacion-growing-up {
  transition: all 0.5s;
}

.animacion-growing-up:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.animacion-growing-up-small {
  transition: all 1s;
}

.animacion-growing-up-small:hover {
  transform: scale(1.1);
}

.input-second {
  border: 7px solid #abd9f2;
  border-radius: 39px;
}

/* scroll del contenido que tiene muchas lineas */
.scroll-content {
  max-height: calc(80vh - 2rem);
  overflow-y: auto;
}

.search {
  background: #ffffff;
  border: 1px solid #454545;
  box-sizing: border-box;
  border-radius: 47px;
}

@media (max-width: 776px) {
  .box-medio-humano {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .scroll-content {
    max-height: calc(50vh - 2rem);
    overflow-y: auto;
    overflow-x: auto;
  }
}

/* oculta la barrita del scroll sin afectar el funcionamiento */
@media (min-width: 700px) {
  ::-webkit-scrollbar {
    display: none;
  }
}
