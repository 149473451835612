.phrase-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.phrase-dictionary {
  font-weight: normal;
  font-size: large;
  color: #ffffff;
  text-align: start;
  text-align: justify;
}

.phrase-translation-dictionary {
  font-weight: normal;
  font-size: medium;
  color: #81b5ee;
  text-align: start;

  margin-top: 4px;
  text-align: justify;
}
