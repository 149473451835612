.warning-flashcard-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning-text-flashcard {
  font-weight: 300;
  font-size: medium;
  color: #FFD850;
  padding: 0;
  margin: 0;
}