.btn-traductor-general-dark,
.btn-traductor-general-blue {
  margin: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  box-shadow: -0.2em 0.3em 0.4em #0c0c0c, 0.3em -0.2em 0.5em #2f4156;
}

.btn-traductor-general-blue {
  background: #1b2734;
}

.btn-traductor-general-dark {
  background: #0c1218;
}

.btn-traductor-general-dark:focus,
.btn-traductor-general-blue:focus {
  box-shadow: -0.2em 0.3em 0.4em #0c0c0c, 0.3em -0.2em 0.5em #2f4156;
}

.rotating {
  /* animation: rotar_traductor 1.5s ease infinite; */
  -webkit-animation: rotating 1.5s linear infinite;
  -moz-animation: rotating 1.5s linear infinite;
  -ms-animation: rotating 1.5s linear infinite;
  -o-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
}

/* rotar traductor btn */
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
