.traduccion-help {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 1px;
}

.phrase-help {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
}

.list-unstyled {
  margin-left: 10px;
}

.list-unstyled li {
  border-radius: 5px;
  padding-left: 5px;
}

.list-unstyled li:hover {
  background: #0c121885;
}

.list-unstyled li .media-body {
  margin-top: 3px;
  margin-bottom: 3px;
}

.contendor-example-ver-mas {
  display: flex;
  justify-content: center;
}
