.mediatext__main__card__footer {
  padding: 5px;
}

.mediatext__karaoke__text__original__container {
  word-wrap: break-word;
  animation: letra_inicio 2s;
  text-align: center;
}

.mediatext__karaoke__text__original {
  font-weight: 500;
  font-size: 4vw;
  line-height: 1.1;
  color: #fff;
  display: inline-block;
  cursor: pointer;
}

.mediatext__karaoke__text__traduction {
  font-weight: 300;
  font-size: 2.3vw;
  line-height: 1.1;
  color: #ffffff;
  margin-left: 10px;
  text-align: center;
  word-wrap: break-word;
  animation: letra_inicio 2s;
  margin-right: 55px;
}

.mediatext__complete__text__sentence {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  display: inline-block;
  cursor: pointer;
}

.mediatext__complete__text__translation {
  font-size: 15px;
  line-height: 1.1;
  padding: 0;
  margin: 0;
  color: #c4c4c4;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.mediatext__complete__text__sentence:hover,
.mediatext__karaoke__text__original:hover {
  color: rgb(16, 129, 221);
  background: rgba(110, 109, 109, 0.158);
  border-radius: 7px;
  transition: all 0.5s;
}

.mediatext__principal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.verification-media-text-container {
  display: flex;
  justify-content: flex-end;
}

.card-space {
  background: #f2f2f2;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.22);
  border-radius: 12px;
  height: 300px;
}

.mediatext-progress {
  width: 250px;
}

.letraBuscar {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;

  color: #8b8b8b;
}

.text-tbody {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;

  color: #8b8b8b;
}

.text-song {
  font-style: normal;
  font-weight: 550;
  font-size: 17px;
  color: #70bbe3;
}

.text-grey {
  position: absolute;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  color: #8b8b8b;
}

.text-normal {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #8b8b8b;
}

.text-title {
  position: absolute;
  width: 59px;
  height: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15;
  color: #4096c4;
}

.text-collage-secondary {
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 19px;

  color: #8b8b8b;
}

.text-modal-item {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  color: rgb(135, 138, 140);
}

.collage-header {
  height: 45px;
}

.scroll-content-collage {
  max-height: calc(50vh - 2rem);
  overflow-y: auto;
}

.mano {
  cursor: pointer;
}

.scroll-content-table {
  max-height: calc(20vh - 2rem);
  overflow-y: auto;
}

.contenedor-loading-mediatext {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.loading-parsing-text {
  color: white;
  margin: 15px;
}

.contendor-letra-mediatext {
  display: flex;
  margin-bottom: 50px;
}

.contendor-letra-karaoke-complete {
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 25px;
}

.contenedor-btn-back {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -45px;
}

@media (max-width: 800px) {
  .badge {
    font-size: 10px;
  }

  .btn-traductor {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -45px;
  }
}

/* COLORES DE FRECUENCIA DE LAS PALABRAS */
.demasiado-frecuente {
  color: #7fe55b;
}

.muy-frecuente {
  color: #69e5de;
}

.frecuente {
  color: #21bdff;
}

.medio-frecuente {
  color: #267dff;
}

.menos-frecuente {
  color: #4434fb;
}

/* COLORES DE FRECUENCIA DE LAS PALABRAS YA APRENDIDAS */
.demasiado-frecuente-ya {
  color: white; /* fallbac */
  -webkit-text-fill-color: #1b2734; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: #7fe55b;
}

.muy-frecuente-ya {
  color: white; /* fallbac */
  -webkit-text-fill-color: #1b2734; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: #69e5de;
}

.frecuente-ya {
  color: white; /* fallbac */
  -webkit-text-fill-color: #1b2734; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: #21bdff;
}

.medio-frecuente-ya {
  color: white; /* fallbac */
  -webkit-text-fill-color: #1b2734; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: #267dff;
}

.menos-frecuente-ya {
  color: white; /* fallbac */
  -webkit-text-fill-color: #1b2734; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: #4434fb;
}

.small-word {
  -webkit-text-stroke-width: 0.24px !important;
}

.loading-search-mediatext {
  height: calc(100vh - 280px);
}

/* transiciones y efectos */
@keyframes AnimationScroll {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* transiciones y efectos */
@keyframes letra_inicio {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*Tablet*/
/*Portrait*/
/* @media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {} */

/*landscape*/
/* @media (min-width: 991.98px) and (max-width: 1199.98px) and (orientation: landscape) {} */
/*fin tablet*/

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .mediatext__complete__text__sentence {
    font-size: 15px;
  }

  .mediatext__complete__text__translation {
    font-size: 12px;
    line-height: 1.1;
    margin-top: 0;
  }

  .mediatext__karaoke__text__original {
    font-weight: bold;
    font-size: 22px;
    line-height: 1.1;
  }

  .mediatext__karaoke__text__traduction {
    font-weight: 300;
    font-size: 17px;
    line-height: 1.1;
    color: #c7c5c5;
    text-align: start;
  }

  .mediatext__karaoke__text__original__container {
    width: 100%;
    text-align: start;
  }

  .mediatext__principal__header {
    overflow: auto;
  }

  .mediatext__principal__header::-webkit-scrollbar {
    display: none;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .mediatext__complete__text__sentence {
    font-size: 15px;
  }

  .mediatext__complete__text__translation {
    font-size: 12px;
    line-height: 1.1;
    margin-top: 0;
  }

  .mediatext__karaoke__text__original {
    font-weight: bold;
    font-size: 22px;
    line-height: 1.1;
  }

  .mediatext__karaoke__text__traduction {
    font-weight: 300;
    font-size: 17px;
    line-height: 1.1;
    color: #c7c5c5;
    text-align: start;
  }

  .mediatext__karaoke__text__original__container {
    width: 100%;
    text-align: start;
  }
}
/*fin movil*/
