@import "~react-image-gallery/styles/css/image-gallery.css";

.images-gallery-container {
  background: #141d26c1;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  border: 2px solid rgba(47, 73, 143, 0.53);
  border-radius: 20px;
}

.image-original-galley {
  background-size: 100 100%;
  background-position: center;
  background-size: cover;
}

.image-gallery-slide {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-gallery-thumbnail {
  max-width: 75px;
  max-height: 75px;
  min-width: 75px;
  min-height: 75px;
  background-size: 100 100%;
  background-position: center;
  background-size: cover;
  border: 1px solid rgb(47, 72, 143);
  margin: 5px;
  margin-top: 15px;
}
