.networks__alert__main {
  border-radius: 7px;
  width: 30px;
  transition: 1s all;
  padding: 1px;
}

.networks__alert__main:hover {
  background: rgba(245, 22, 18, 0.119);
  transform: scale(1.3);
}
