.input-range__label {
  display: none;
}

.input-range__slider {
  height: 12px;
  width: 12px;
  max-height: 12px;
  max-width: 12px;
  min-height: 12px;
  min-width: 12px;
  margin-top: -8.2px;
  margin-left: -2px;
  background: linear-gradient(233.13deg, #fff4f5 0.44%, #ffffff 100.88%),
    linear-gradient(233.13deg, #ff5562 0.44%, #fd9d5d 100.88%);
  border: none;
}

.input-range__track {
  height: 5px;
  background: rgba(190, 197, 255, 0.37);
  border-radius: 10px;
}

.input-range__track--active {
  background: #ffffff;
  border-radius: 10px;
  height: 5px;
}

/* .input-range__track,
.input-range__slider,
.input-range__slider-container {
  transition: none;
} */

.divider-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  bottom: -16px;
}
