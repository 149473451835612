.audioContainer canvas{
    display: none !important;
}

.icon img{
    width: 30px;
}


.btn img{
    transition: all .2s ease-in-out;
}

.btn img:hover {
    transform: scale(1.2);
}

.audio-player-container{
    display: flex;
    align-items: center;
}

.btn-player-audio{
    background-color: transparent;
    margin-left: 5px;
    border: 0;
}

.btn-player-audio:focus{
    outline: none;
}

.player-audio-icon{
    width: 22px;
}

.player-information{
    width: 150px;
    display: flex;
    justify-content: center;
}

.contenedor-progress-player{
    width: 80px;
    margin: 7px;
}

.player-texts{
    color: rgba(250, 235, 215, 0.776);
    font-size: 12px;
    line-height: 15px;
}