.msg-confirmation{
  font-weight: normal;
  font-size: 25px;
  text-align: center;
  color: #FFFFFF;
}

.btn-transparent{
  width: auto;
  height: 47px;

  font-weight: normal;
  font-size: 20px;
  color: #FFFFFF;
  background: transparent;
  border: 2px solid transparent;
}

.btn-outline-white{
  width: auto;
  height: 47px;

  font-weight: normal;
  font-size: 20px;
  color: #FFFFFF;

  border: 2px solid #FFFFFF;
  /*box-sizing: border-box;*/
  border-radius: 7px;
  background: transparent;
}

.btn-transparent:focus, .btn-outline-white:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-transparent:hover{
  border: 2px solid #FFFFFF;
  /*box-sizing: border-box;*/
  border-radius: 7px;
  background: transparent;
  color: #ffffff;
}

.btn-outline-white:hover{
  background: #ffffff;

  color: #1b2734;
}

.btn-transparent:hover, .btn-outline-white:hover {
  font-weight: normal;
  font-size: 20px;
}