.CircularProgressbar {
  /*width: 100%;*/
  width: 100%;
  min-height: 70px;
  border-radius: 50%;
  padding: 0px;
  /*box-shadow: -0.3em 0.6em 0.5em #0c0c0c,
    0.2em -0.5em 0.9em #2F4156;*/
}

.CircularProgressbar .CircularProgressbar-path-flashcard {
  stroke: rgb(233, 83, 91);
  stroke-linecap: round;
  stroke-width: 2.3mm;
  transition: stroke-dashoffset 2s ease 0s;
}

.CircularProgressbar .CircularProgressbar-path-input {
  stroke: rgb(31, 216, 34);
  stroke-linecap: round;
  stroke-width: 2.3mm;
  transition: stroke-dashoffset 2s ease 0s;
}

.CircularProgressbar .CircularProgressbar-path-output {
  stroke: #1381f7;
  stroke-linecap: round;
  stroke-width: 2.3mm;
  transition: stroke-dashoffset 2s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d1cbcb8c;
  stroke-width: 2.2mm;
}

.CircularProgressbar .CircularProgressbar-title {
  fill: #f8f5f5;
  font-size: 10px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #f8f5f5;
  dominant-baseline: middle;
  text-anchor: middle;
  font-weight: normal;
  font-size: 21px;
}

.CircularProgressbar .CircularProgressbar-percsign {
  baseline-shift: super;
  font-size: 0.4em;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #1b2734;
}
