.spotify-settings {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 100px;
}

.text {
  font-weight: normal;
  font-size: large;
  line-height: 24px;
  text-align: center;
  color: #1B2734;
}