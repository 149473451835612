.loading-coco {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .loading-coco img {
    transform: scale(0.7);
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .loading-coco img {
    transform: scale(0.7);
  }
}
/*fin movil*/
