.create__note__container {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#main-title {
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 25px;
}

#note-title {
  outline: none;
  border: 0;
  width: 75vw;
  background: #0f161d;
  border-radius: 7px;
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  color: rgb(145, 144, 144);
}

#note-title::placeholder,
#note-body::placeholder {
  color: rgba(255, 255, 255, 0.618);
}

.btn-outline-linear-gradient-note {
  width: 151.93px;
  height: 44.8px;
  text-align: center;
  border-radius: 7px;
  display: flex;
}

.btn-outline-linear-gradient-note span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  border-radius: 7px;
  flex: 1;
  margin: 1px;
  transition: box-shadow 0.5s;
  box-shadow: inset 0 0 0 40px #1b2734;
}

.btn-outline-linear-gradient-note:hover span {
  box-shadow: inset 0 0 0 40px transparent;
  cursor: pointer;
}

#btn-outline-add-note {
  background: linear-gradient(191.49deg, #ff5962 16.06%, #fd935e 91.7%);
}

#calendario-y-arroba {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.date-string {
  margin-right: 10px;
  font-size: 18px;
  color: #ffffff;
}

#calendario-y-arroba:hover {
  cursor: pointer;
}

#selector-languages-editor {
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: 1;
  background: #0f161d;
}

.selector-languages-editor-list {
  height: 200px;
  background: #0f161d;
}

@media (max-width: 700px) {
  #note-title {
    width: 100vw;
  }
}
