.add-participants-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;
  max-width: 100%;
  min-height: 400px;
  height: 75vh;
  padding: 55px 40px 20px 40px;

  background: #1b2734;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.participantes-ya-agregados-al-grupo {
  display: flex;
  align-items: center;
  overflow: auto;
}

.participantes-encontrados {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-agregar-participantes {
  text-align: center;
  display: flex;
  justify-content: center;
}

.participantes-ya-agregados-contenedor {
  max-width: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.participantes-ya-agregados-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.participantes-selected-name {
  font-weight: 300;
  font-size: 10px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.badge-remover-users {
  min-width: 22px;
  min-height: 22px;
  border-radius: 50%;
  background: rgba(13, 24, 36, 0.9);
  position: absolute;
  color: white;
  top: -7px;
  right: -7px;
  z-index: 1;
  cursor: pointer;
  transition: 1s all;
  text-align: center;
  line-height: 1.2;
}

.badge-remover-users:hover {
  transform: scale(1.2);
}
