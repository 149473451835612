.btn-login-spotify {
  background: transparent;
  margin-bottom: 25px;
}

.btn-login-spotify:hover {
  background: rgb(85, 78, 128);
  border-radius: 10px;
}

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .btn-login-spotify {
    margin-bottom: 80px;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .btn-login-spotify {
    margin-bottom: 80px;
  }
}
/*fin movil*/
