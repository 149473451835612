.footer-spotify-player {
  height: 70px;
  width: calc(100vw - 180px);
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 22px;

  background: linear-gradient(
    180deg,
    rgba(103, 162, 250, 0.35) 0%,
    rgba(14, 44, 77, 0.5) 100%
  );
  backdrop-filter: blur(23px);
  border-radius: 35px 35px 0px 0px;
  transition: 0.3s all;
}

.footer-spotify-player-big {
  width: calc(100vw - 110px);
}

.footer__with__topbar__navigation {
  height: calc(50px + 50px);
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .footer-spotify-player {
    width: auto;
    right: 0px;
    left: 0px;
    border-radius: 0;
  }
}

/*landscape*/
@media (min-width: 991.98px) and (max-width: 1199.98px) and (orientation: landscape) {
  .footer-spotify-player {
    width: auto;
    right: 0px;
    left: 0px;
    border-radius: 0;
  }
}
/*fin tablet*/

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .footer-spotify-player {
    width: auto;
    right: 0px;
    left: 0px;
    border-radius: 0;
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .footer-spotify-player {
    width: auto;
    right: 0px;
    left: 0px;
    border-radius: 0;
  }
}
/*fin movil*/
