.flashcard-option-student {
  background-color: black;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 92%;
  width: 200px;
  height: 300px;
  padding: 20px;
}

.translation__flashcard__title {
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.input-search-flashcard {
  display: flex;
  justify-content: center;
}

.custom-input-front {
  font-weight: bold;
  font-size: 1.25em;
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  max-height: 340.79px;
}

.custom__input__back {
  margin: 0 auto;
  font-weight: 500;
  font-size: 1.25em;
  border: none;
  text-align: center;
  color: #4f4f4f;
}

.orange-text-gradient-student {
  background: -webkit-linear-gradient(182.95deg, #ff5962 16.06%, #fd935e 91.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flashcard-option-subtitle-student {
  color: white;
}

.my-flashcard-container {
  margin-left: 20px;
  margin-bottom: 20px;
}

.flashcard-options-container-student {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: start; */
  font-size: 20px;
}

.orange-text-translation {
  color: #4f4f4f;
  font-size: 20px;
  text-align: center;
  justify-content: center;
}

/*input search
 */

.searchbox-flashcard {
  /*definint width of form element*/
  width: 400px;
  /*centering the form element*/
  margin: 84px auto 60px auto;
}

input[type='search-flashcard'] {
  padding-left: 62px;
  font-size: 36px;
  color: #1f5350;
  /*removing boder from search box*/
  border: none;
  /*defining background image as a search symbol*/
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 27px;

  /*background-size*/
  -webkit-background-size: 25px 25px;
  -moz-background-size: 25px 25px;
  -o-background-size: 25px 25px;
  background-size: 25px 25px;
  background-color: #ffffff;
  border-radius: 3px;
  opacity: 0.9;
  width: 380.36px;
  height: 44.75px;
  font-size: 18px;
  margin-left: 12px;
}

/*now using placeholder property to change color of placholder text and making it consitent accross the browser by use of prefix*/
input[type='search-flashcard']::-webkit-input-placeholder {
  color: #4f4f4f;
}

input[type='search-flashcard']:-moz-placeholder {
  /* Firefox 18- */
  color: #4f4f4f;
}

input[type='search-flashcard']::-moz-placeholder {
  /* Firefox 19+ */
  color: #4f4f4f;
}

input[type='search-flashcard']:-ms-input-placeholder {
  /* interner explorer*/
  color: #4f4f4f;
}

.flashcard-grow {
  transition: all 0.2s ease-in-out;
}

.flashcard-grow:hover {
  transform: scale(1.1);
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .flashcard__mobile__container {
    position: relative;
    max-width: 300px;
    max-height: 210px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}

/*landscape*/
@media (min-width: 991.98px) and (max-width: 1199.98px) and (orientation: landscape) {
  .flashcard__mobile__container {
    position: relative;
    max-width: 300px;
    max-height: 210px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}

/*fin tablet*/

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .flashcard__mobile__container {
    position: relative;
    max-width: 285px;
    max-height: 192px;
    margin-bottom: 15px;
  }
}

/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .flashcard__mobile__container {
    position: relative;
    max-width: 285px;
    max-height: 192px;
    margin-bottom: 15px;
  }
}

/*fin movil*/