.contenedor-context-menu-playlist {
  position: relative;
  margin: 8px;
}

.context-menu-playlist,
.context-menu-playlist-dashboard {
  position: absolute;
  width: 210px;
  top: 25px;
  left: -8px;
  background: rgb(37, 53, 71);
  border-radius: 7px;
  z-index: 10000;
}

.arrow-over-container:before {
  content: '';
  position: absolute;
  top: 15px;
  left: 3.1px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(37, 53, 71);
}
.context-menu-playlist-dashboard {
  max-height: 200px;
  overflow-y: scroll;
  z-index: 10000;
}

.context-sub-menu-playlist-dashboard {
  position: absolute;
  background: rgb(37, 53, 71);
  width: 210px;
  height: 200px;
  top: 25px;
  left: -8px;
  border-radius: 7px;
  overflow: scroll;
  z-index: 20000;
}

.context-sub-menu-playlist {
  position: absolute;
  background: rgb(37, 53, 71);
  width: 210px;
  min-height: 200px;
  top: 25px;
  left: 205px;
  border-radius: 7px;
  z-index: 10000;
  overflow: auto;
}

.btn-context-menu-playlist {
  cursor: pointer;
  background: transparent;
  border: none;
}

.btn-context-menu-playlist:focus {
  outline: none;
}

.context-menu-playlist-item {
  min-height: 40px;
  padding-top: 6%;
  padding-bottom: 6%;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;

  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}

.context-menu-playlist-item:hover {
  background: rgba(100, 148, 237, 0.123);
}

.border-divider-item {
  border-bottom: 1px solid rgb(57, 80, 105);
  margin-right: 10px;
  margin-left: 10px;
}

.menu-playlist-item-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 85%;
  height: 20px;
}

.menu-playlist-item-icon {
  width: 15%;
}
