.reproductor-spotify {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 70px;
}

.reproductor-item-info {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  width: 30%;
}

.reproductor-item-controls {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reproductor-item-progreso {
  width: 40%;
  flex: 1;
}

.reproductor-item-controls-secondary {
  /* width: 15%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.progreso-cantidad {
  width: 90%;
}

.go-up {
  transition: all 0.3s;
}

.go-up:hover {
  transform: scale(1.1);
}

.like-music-reproductor {
  margin-top: -15px;
  margin-right: 3px;
  cursor: pointer;
}

.information__track__player {
  width: calc(100% - 64px);
}

.text-artist-contenedor {
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;

  color: #c4c4c4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -5px;
}

.text-artist {
  color: #c4c4c4;
}

.text-artist:hover {
  color: #ffffff;
}

.player__track__title {
  font-size: 15px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.spotify__progress__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spotify__progress__container {
  height: 100%;
  width: calc(100%);
}

.spotify__progress__timers {
  font-weight: 500;
  font-size: 9px;
  color: #ffffff;
  width: 50px;
  text-align: center;
}

#track {
  width: auto;
  height: 4px;
  background: #c4c4c4;
  border-radius: 10px;
  left: 17px;
  cursor: pointer;
}

#progress2 {
  float: left;
  height: 4px;
  width: 0%;
  background: #8b8b8b;
  border-radius: 10px;
  cursor: pointer;
}

.foto {
  width: 35px;
  height: 35px;
  margin-right: 12px;
  margin-left: 10px;
}

.player__item__button {
  background: transparent;
  width: 45px;
  height: 45px;
  text-align: center;
  transition: 1s all;
}

.player__item__karaoke {
  background: transparent;
  text-align: center;
  transition: 1s all;
}

.player__spotify__play__button {
  background: transparent;
  width: 30px;
  height: 47.08px;
  margin-left: 15px;
  margin-right: 15px;
  transition: 1s all;
}

.player__spotify__play__button:hover,
.player__item__button:hover,
.player__item__karaoke:hover {
  transform: scale(1.2);
}

.player__spotify__play__button img {
  width: 100%;
  height: 100%;
}

/* loading  */
/* Loading animation container */
.loading_player {
  position: relative;
  width: 28px;
  height: 28px;
}

/* Spinning circle (inner circle) */
.loading_player .maskedCircle__player {
  width: 20px;
  height: 20px;
  border-radius: 12px;
  border: 3px solid white;
}

/* Spinning circle mask */
.loading_player .mask__player {
  width: 12px;
  height: 12px;
  overflow: hidden;
}

/* Spinner */
.loading_player .spinner__player {
  position: absolute;
  left: 1px;
  top: 1px;
  width: 26px;
  height: 26px;
  animation: spin 1s infinite linear;
}

/* Animation keyframes - you need to add prefixes */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .reproductor-spotify {
    padding-top: 7px;
    height: 50px;
  }

  .reproductor-item-progreso {
    position: absolute;
    top: -6px;
    width: 97%;
  }

  .spotify__progress__timers {
    display: none;
  }

  .reproductor-item-controls {
    width: 55%;
  }

  .reproductor-item-info {
    width: 45%;
    transform: scale(0.9);
  }

  .player__spotify__play__button {
    width: calc(30px - 10px);
    height: calc(47.08px - 10px);
    margin-left: 5px;
    margin-right: 5px;
  }

  .player__item__button img {
    width: calc(30px - 10px);
    height: calc(47.08px - 10px);
  }
}

/*landscape*/
@media (min-width: 991.98px) and (max-width: 1199.98px) and (orientation: landscape) {
  .reproductor-spotify {
    padding-top: 7px;
    height: 50px;
  }

  .reproductor-item-progreso {
    position: absolute;
    top: -6px;
    width: 97%;
  }

  .spotify__progress__timers {
    display: none;
  }

  .reproductor-item-controls {
    width: 55%;
  }

  .reproductor-item-info {
    width: 45%;
    transform: scale(0.9);
  }

  .player__spotify__play__button {
    width: calc(30px - 10px);
    height: calc(47.08px - 10px);
    margin-left: 5px;
    margin-right: 5px;
  }

  .player__item__button img {
    width: calc(30px - 10px);
    height: calc(47.08px - 10px);
  }
}
/*fin tablet*/

/*movil*/
/*Portrait*/
/*Default*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .reproductor-spotify {
    padding-top: 7px;
    height: 50px;
  }

  .reproductor-item-progreso {
    position: absolute;
    top: -6px;
    width: 97%;
  }

  .spotify__progress__timers {
    display: none;
  }

  .reproductor-item-controls {
    width: 55%;
  }

  .reproductor-item-controls-secondary {
    display: none;
  }

  .foto {
    display: none;
  }

  .reproductor-item-info {
    width: 40%;
    padding-left: 20px;
    transform: scale(0.9);
  }

  .player__spotify__play__button {
    width: calc(30px - 10px);
    height: calc(47.08px - 10px);
    margin-left: 5px;
    margin-right: 5px;
  }

  .player__item__button img {
    width: calc(30px - 10px);
    height: calc(47.08px - 10px);
  }
}
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: landscape) {
  .reproductor-spotify {
    padding-top: 7px;
    height: 50px;
  }

  .reproductor-item-progreso {
    position: absolute;
    top: -6px;
    width: 97%;
  }

  .spotify__progress__timers {
    display: none;
  }

  .reproductor-item-controls {
    width: 55%;
  }

  .reproductor-item-controls-secondary {
    display: none;
  }

  .foto {
    display: none;
  }

  .reproductor-item-info {
    width: 40%;
    padding-left: 20px;
    transform: scale(0.9);
  }

  .player__spotify__play__button {
    width: calc(30px - 10px);
    height: calc(47.08px - 10px);
    margin-left: 5px;
    margin-right: 5px;
  }

  .player__item__button img {
    width: calc(30px - 10px);
    height: calc(47.08px - 10px);
  }
}
/*fin movil*/
