#new-thread {
  width: 681px;
}

#new-thread #thread-title {
  background: #1b2734;
  border-radius: 7px;

  font-size: 20px;
  line-height: 23px;
  color: white;
  border: none;
}

#new-thread #thread-title::placeholder {
  color: #6182a6;
}

#new-thread #first-message {
  position: relative;
  background: #1b2734;
  border-radius: 7px;

  font-weight: normal;
  font-size: 18px;
  line-height: 23px;

  color: white;
  border: none;
  resize: none;
  height: 25vh;
}

#firt-message-area {
  background-color: transparent;
  max-height: 80%;
  height: 80%;
  width: 100%;
  border: none;
  color: white;
  font-size: 20px;
  line-height: 23px;
  resize: none;
}

#new-thread #first-message::placeholder,
#firt-message-area::placeholder {
  color: #6182a6;
}

#selector-languages-new-conversation {
  position: absolute;
  bottom: 5px;
  left: 3px;
}

.hilo-closed-title {
  margin: 20px;
  align-content: center;
  display: flex;
  align-items: center;
}

.hilo-closed-title span {
  margin-left: 10px;
  line-height: 30px;
  font-size: 23px;
  color: #81b5ee;
}
