#titulo-de-otros-estudiantes {
	font-weight: normal;
	font-size: 20px;
	color: #ffffff;
	margin-bottom: 30px;
}

#container-other-users {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

#foto-del-usuario {
	height: 95px;
	width: 95px;
	margin: 5px;
	background-repeat: no-repeat;
	background-position: 50%;
	border-radius: 50%;
	background-size: 100% 100%;
	cursor: pointer;
}

#foto-del-usuario:hover #nombre-del-usuario {
	visibility: visible;
}

#nombre-del-usuario {
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	font-weight: 500;
	font-size: 13px;
	color: #ffffff;
	text-transform: capitalize;
	visibility: hidden;
}
