.flashcard-option-subtitle {
    color: white;
}

.flashcard-options-container {
    display: flex;
    justify-content: space-around;
    font-size: 25px;
}

.flashcard-option {
    height: 152px;
    width: 290px;
    background-color: black;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;

}