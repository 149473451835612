.loading__page__wrapp {
  width: 100%;
  height: calc(100vh - 320px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading__spinner__page {
  position: relative;
  width: 45px;
  height: 45px;
  margin: 0 auto;
}

.loading__page__bubble__1,
.loading__page__bubble__2 {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #4b9cdb;
}

.loading__page__bubble__2 {
  top: auto;
  bottom: 0;
}

.loading__page__container .loading__spinner__page {
  animation: loadingI 2s linear infinite;
}
.loading__page__container .loading__page__bubble__1,
.loading__page__container .loading__page__bubble__2 {
  animation: bounce 2s ease-in-out infinite;
}
.loading__page__container .loading__page__bubble__2 {
  animation-delay: -1s;
}

@keyframes loadingI {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
