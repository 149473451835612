.change-language-platform-botton {
  background-color: transparent;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.change-language-platform-icon {
  margin-right: 4px;
}

.change-language-platform-name {
  color: #81b5ee;
  font-size: 15px;
  margin-left: 4px;
}

.change-language-platform-container {
  position: relative;
  text-align: center;
}

.change-language-platform {
  width: 153px;
  background: #101821;
  border-radius: 7px;
  position: absolute;
  right: 0px;
  top: 30px;
  padding: 15px;
}

.change-language-platform-title {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
}

.change-language-item-title {
  color: #81b5ee;
  font-size: 15px;
  line-height: 200.5%;
  cursor: pointer;
}

.change-language-platform:before {
  content: "";
  position: absolute;
  right: 10px;
  top: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #101821;
}
