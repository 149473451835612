.left-message-docuchat {
  width: 500px;
  border-radius: 46px;
  min-height: 42px;
  background: #3f5165;
  padding: 20px;
  margin: 10px;
  word-wrap: break-word;
  white-space: pre-line;
}

.rigth-message-docuchat {
  width: 500px;
  border-radius: 46px;
  min-height: 42px;
  background: #0b1622;
  padding: 20px;
  margin: 10px;
  z-index: 0;
  word-wrap: break-word;
  white-space: pre-line;
}

.message-citado-temporal {
  background: rgba(63, 81, 101, 0.283);
  transition: 1s all;
  border-radius: 15px;
}

.message-citado-text {
  background: rgba(63, 81, 101, 0.5);
  border-radius: 30px 30px 7px 7px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  padding: 15px;
  margin: -10px;
  margin-bottom: 15px;
  cursor: pointer;
  white-space: pre-line;
}
.message-citado-text-left {
  background: rgba(11, 22, 34, 0.5);
  border-radius: 30px 30px 7px 7px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  padding: 15px;
  margin: -10px;
  margin-bottom: 15px;
  cursor: pointer;
  white-space: pre-line;
}

/* para mini chats */
.left-message-minichat {
  width: 250px;
  border-radius: 46px;
  min-height: 42px;
  background: #3f5165;
  padding: 20px 20px 10px 20px;

  margin: 5px;
  word-wrap: break-word;
  white-space: pre-line;
}

.rigth-message-minichat {
  width: 250px;
  border-radius: 46px;
  min-height: 42px;
  background: #0b1622;
  padding: 20px 20px 10px 20px;

  margin: 5px;
  z-index: 0;
  word-wrap: break-word;
  white-space: pre-line;
}

.translated-message {
  color: #f2c94c;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  margin: 0;
}

.text-content-message {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #d3e8ff;
  margin: 0;
}

.text-message-created {
  display: block;
  font-weight: 300;
  font-size: 13px;
  color: #6c859f;
  text-align: end;
}

.message-item-li-current {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.message-item-li {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.user-photo-message {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.message-image-chat {
  width: 250px;
  height: 250px;
  background-size: cover;
  border-radius: 15px;
  margin: 10px;
  background: #0b1622;
}

.message-image-loading {
  min-width: 250px;
  height: 250px;
  border-radius: 15px;
  margin: 10px;
  background: #0b1622;
  display: flex;
  justify-content: center;
}

@media (max-width: 1193px) {
  .left-message-docuchat {
    width: 300px;
  }

  .rigth-message-docuchat {
    width: 300px;
  }

  .message-image-chat {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 877px) {
  .left-message-docuchat {
    width: auto;
  }

  .rigth-message-docuchat {
    width: auto;
  }

  .message-image-chat {
    width: 100px;
    height: 100px;
  }
}

/* :not(:required) hides this rule from IE9 and below */
.message-citado-temporal:not(:required) {
  -webkit-animation: message-citado-temporal 1300ms ease 0s infinite normal;
  -khtml-animation: message-citado-temporal 1300ms ease 0s infinite normal;
  -moz-animation: message-citado-temporal 1300ms ease 0s infinite normal;
  -ms-animation: message-citado-temporal 1300ms ease 0s infinite normal;
  -o-animation: message-citado-temporal 1300ms ease 0s infinite normal;
  animation: message-citado-temporal 1300ms ease 0s infinite normal;
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@-webkit-keyframes message-citado-temporal {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.03);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.03);
  }
  70% {
    transform: scale(1);
  }
}
@-moz-keyframes message-citado-temporal {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.03);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.03);
  }
  70% {
    transform: scale(1);
  }
}

@-o-keyframes message-citado-temporal {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.03);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.03);
  }
  70% {
    transform: scale(1);
  }
}
@keyframes message-citado-temporal {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.03);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.03);
  }
  70% {
    transform: scale(1);
  }
}
